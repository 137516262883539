import d000 from './icons/d000.svg';
import d100 from './icons/d100.svg';
import d200 from './icons/d200.svg';
import d210 from './icons/d210.svg';
import d211 from './icons/d211.svg';
import d212 from './icons/d212.svg';
import d220 from './icons/d220.svg';
import d221 from './icons/d221.svg';
import d240 from './icons/d240.svg';
import d300 from './icons/d300.svg';
import d320 from './icons/d320.svg';
import d400 from './icons/d400.svg';
import d410 from './icons/d410.svg';
import d411 from './icons/d411.svg';
import d412 from './icons/d412.svg';
import d420 from './icons/d420.svg';
import d430 from './icons/d430.svg';

interface IconMap {
    [name: string]: any
}
const iconMap: IconMap = {
    d000,
    d100,
    d200,
    d210,
    d211,
    d212,
    d220,
    d221,
    d240,
    d300,
    d320,
    d400,
    d410,
    d411,
    d412,
    d420,
    d430,  
}

const mapWeatherIcon = (forecaCode: string) => {
    const dayCode = forecaCode.startsWith('n') ? forecaCode.replace('n', 'd') : forecaCode;
    switch (dayCode) {
      case 'd221':
        return 'd211';
      case 'd222':
        return 'd212';
      case 'd300':
        return 'd200';
      case 'd310':
        return 'd210';
      case 'd320':
        return 'd220';
      case 'd340':
        return 'd240';
      case 'd311':
        return 'd211';
      case 'd321':
        return 'd211';
      case 'd312':
        return 'd212';
      case 'd322':
        return 'd212';
      case 'd430':
        return 'd420';
      case 'd421':
        return 'd411';
      case 'd431':
        return 'd411';
      case 'd432':
        return 'd412';
      case 'd422':
        return 'd412';
      default:
        return dayCode;
    }
};

export function WeatherIcon ({ name }:{ name: string}) {
    const processedIconName = mapWeatherIcon(name);
    
    if (processedIconName in iconMap) {
        return (
            <img src={iconMap[processedIconName]} alt={name}/>
        )
    }
    
    return null
}