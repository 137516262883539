import { Component } from 'react';
import { Flight, FreeSeats, TableItem, TimeAndCity, Weather as WeatherModel } from '../../model/interfaces';
import { AvailabilityThunkDispatch, RootState } from '../../../rootReducer';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Loader from '../Loader/Loader';
import ReactTable, { Column, RowInfo } from 'react-table-6';
import 'react-table-6/react-table.css';
import './table.css';
import { InlineSvgIcon } from '../InlineSvgIcon';
import { FlightDetails } from './FlightDetails/FlightDetails';
import { ColumnSeats } from './TableColumns/ColumnSeats';
import { ColumnCity } from './TableColumns/ColumnCity';
import { ColumnDuration } from './TableColumns/ColumnDuration';
import { boxShadow, color, scale, staticScale } from '../../styles';
// import Filters from '../Filters/Filters';
import { isNotPresent } from '../../../utils/typeUtils';
import { AvailabilityActions } from '../../actions/availability.actions';
import moment from 'moment';
import { iataToFinnairCode } from '../Weather/Weather';
import { ALL_MONTHS, WARM_DESTINATIONS, WARM_DESTINATION_THRESHOLD } from '../Weather/warm-destinations';
import { LOCATIONS } from './locations';

const PETC_MAX_CAPACITY = 2;

interface Props {
  allFlights: Flight[] | null;
  loadingFlights: boolean;
  expandedRows: { [row: number]: boolean };
  weather?: { [city: string]: WeatherModel[] };
  filters: { [filterGroup: string]: { [filter:string]: boolean} };
  setFilter: (filterGroup: string, filterName: string) => void;
  handleExpandRow: (row: number) => void;
  handleResetRows: () => void;
}

interface State {
  filteredFlights: TableItem[],
}

const mapStateToProps = (state: RootState) => {
  return {
    allFlights: state.availability.allFilteredFlights,
    loadingFlights: state.availability.loadingFlights,
    expandedRows: state.availability.expandedRows,
    weather: state.availability.weather,
  };
};

const mapDispatchToProps = (dispatch: AvailabilityThunkDispatch) => ({
  handleExpandRow: (row: number) => {
    dispatch(AvailabilityActions.expandRow(row));
  },
  handleResetRows: () => {
    dispatch(AvailabilityActions.resetRows());
  },
});

const timeAndCityComparator = (a: TimeAndCity, b: TimeAndCity) => {
  if (a.time < b.time) return -1;
  if (a.time > b.time) return 1;
  return 0;
};

const freeSeatsComparator = (a: FreeSeats | null, b: FreeSeats | null) => {
  if (a === null) return -1;
  if (b === null) return 1;
  if (a.seats < b.seats) return -1;
  if (a.seats > b.seats) return 1;
  return 0;
};

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${staticScale.spacing.xxs};
`;

const ExpanderIcon = styled(InlineSvgIcon)`
  height: 18px;
  svg {
    width: 18px;
  }
`;

const expanderIcon = (name: 'chevronDown' | 'chevronRight') => {
  return (
    <IconWrapper>
      <ExpanderIcon name={name} />
    </IconWrapper>
  );
};
const columns: Column[] = [
  {
    Header: 'Available Seats',
    accessor: 'seats',
    Cell: (props) => <ColumnSeats seats={props.value} />,
    sortMethod: freeSeatsComparator,
    defaultSortDesc: true,
    resizable: false,
    id: 'availability',
    style: {
      padding: '8px 2px',
    },
    minWidth: 80,
  },
  {
    Header: 'Departure',
    accessor: 'departure',
    Cell: (props) => <ColumnCity timeAndCity={props.value} showWeather={true} />,
    sortMethod: timeAndCityComparator,
    resizable: false,
    style: {
      padding: '8px 2px',
    },
    minWidth: 80,
  },
  {
    Cell: (props) => <ColumnDuration duration={props.value} />,
    accessor: 'duration',
    sortable: false,
    width: 60,
    resizable: false,
    style: {
      padding: '8px 2px',
    },
  },
  {
    Header: 'Arrival',
    accessor: 'arrival',
    Cell: (props) => <ColumnCity timeAndCity={props.value} showWeather={true} />,
    sortMethod: timeAndCityComparator,
    resizable: false,
    style: {
      padding: '8px 2px',
    },
    minWidth: 80,
  },
  {
    expander: true,
    Expander: ({ isExpanded, ...rest }) => <>{expanderIcon(isExpanded ? 'chevronDown' : 'chevronRight')}</>,
    resizable: false,
    width: 26,
    style: {
      padding: 0,
      textAlign: 'center',
      userSelect: 'none',
    },
  },
];

const calculateDuration = (flight: Flight): string => {
  const departure = moment(`${flight.departureDateUTC} ${flight.departureTimeUTC}`);
  const arrival = moment(`${flight.arrivalDateUTC} ${flight.arrivalTimeUTC}`);
  const duration = moment.duration(arrival.diff(departure));
  return moment.utc(duration.as('milliseconds')).format('H[h] m[min]');
};

const isNextDayArrival = (flight: Flight): boolean => {
  return flight.arrivalDateLocal > flight.departureDateLocal;
};

const getFlightRows = (filters: { [filterGroup: string]: { [filter:string]: boolean} }, flights: Flight[] | null, weather?: { [city: string]: WeatherModel[] }): TableItem[] => {
  const allDestinationsForArrival = isAllDestinationsForArrival(flights);
  return flights === null
    ? []
    : flights
        .map((flight) => {
          return {
            flightName: flight.flightDesignator,
            departure: {
              date: flight.departureDateLocal,
              time: flight.departureTimeLocal,
              city: flight.departureCity,
              airportCode: flight.departureStationIATA,
            },
            arrival: {
              date: flight.arrivalDateLocal,
              time: flight.arrivalTimeLocal,
              city: flight.arrivalCity,
              airportCode: flight.arrivalStationIATA,
              isNextDay: isNextDayArrival(flight),
            },
            seats: {
                  seats: flight.availability?.seatsAvailable,
                  petSeats: (PETC_MAX_CAPACITY - flight.ssrCounts?.PETC ?? 0),
                  timestamp: flight.availability?.timestamp,
                },
            duration: calculateDuration(flight),
          };
        }).filter((flight) => {
          if (filters.features.warmDestinations) {
            return filterByWeather(flight, allDestinationsForArrival, weather);
          }
          return true;
        }).filter((flight) => {
          return filterByLocation(flight, filters.locations, allDestinationsForArrival);
        });
};

// If All Destinations is selected for the departure, we assume that people want to find out where they can get back home from
const isAllDestinationsForArrival = (flights: Flight[] | null): boolean => {
  if (flights) {
    let arrivalCity = '';
    for (const flight of flights) {
      if (!arrivalCity) {
        arrivalCity = flight.arrivalCity;
      }
      if (arrivalCity !== flight.arrivalCity) {
        return true;
      }
    }
    return false;
  }
  return true;
}

const filterByWeather = (flight: any, allDestinationsForArrival: boolean, weather?: { [city: string]: WeatherModel[] }) => {
  const actualArrival = allDestinationsForArrival ? flight.arrival : flight.departure;
  const finnairAirportCode = iataToFinnairCode(actualArrival.airportCode);
  for(const cityWeather of weather?.[finnairAirportCode] || []) {
    if (cityWeather.date === actualArrival.date) {
      const maxTemp = cityWeather?.maxTemp;
      if(maxTemp) {
        if (maxTemp >= WARM_DESTINATION_THRESHOLD) {
          return true;
        }
        return false;
      }
    }
  }
  const flightMonth = new Date(flight.arrival.date).getMonth() + 1;
  if (WARM_DESTINATIONS[finnairAirportCode]?.includes(ALL_MONTHS) ||
  WARM_DESTINATIONS[finnairAirportCode]?.includes(flightMonth)) {
    return true;
  }
  return false;
}

const filterByLocation = (flight: any, locations: {[location: string]: boolean }, allDestinationsForArrival: boolean ) => {
  const actualArrival = allDestinationsForArrival ? flight.arrival : flight.departure;
  for (const key in locations) {
    if (locations[key]) {
      if(LOCATIONS[key].includes(actualArrival.airportCode)) {
        return true;
      }
      return false;
    }
  }
  return true;
}

const getBorderColor = (rowInfo?: RowInfo) => {
  if (!rowInfo) return color.borderGray;
  const item: TableItem = rowInfo.original;
  if (!item || !item.seats) return color.borderGray;
  if (item.seats.seats > 10) return color.borderGreen;
  if (item.seats.seats > 0) return color.borderYellow;
  return color.borderRed;
};

const WarningText = styled.div`
  margin-top: ${scale.spacing.m};
  font-size: ${scale.font.m};
  line-height: ${scale.lineHeight.m};
  color: ${color.black};
  text-align: center;
`;

const FilterList = styled.div`
  margin-bottom: 0.0rem;
  `;

const FilterGroup = styled.div`
  display: inline-block;
  margin-right: 0.8rem;
  `;

const Filter = styled.div`
  width: auto;
  margin: 0.2rem;
  margin-bottom: 0.4rem;
  padding: 0.2rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  text-align: center;
  border-radius: 0.4rem;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  &.enabled {
    background-color: ${color.blue};
    border: 1px solid ${color.blue};
    color: ${color.white};
  };
  &.disabled {
    background-color: ${color.white};
    border: 1px solid ${color.blue};
    color: ${color.blue};
  };
`;

export class FlightList extends Component<Props, State> {
  allFlightsLength = 0;
  allFlightsString = '';
  filtersString = '';

  constructor(props: Props) {
    super(props);
    this.state = {
      filteredFlights: getFlightRows(this.props.filters, this.props.allFlights, this.props.weather),
    };
  }

  expandRow(row?: RowInfo) {
    if (isNotPresent(row)) {
      return;
    }
    this.props.handleExpandRow(row.viewIndex);
  }

  componentDidUpdate() {
    let filters = this.props.filters;
    const allFlights = this.props.allFlights;
    const allFlightsString = JSON.stringify(allFlights);
    const filtersString = JSON.stringify(filters);
    if (allFlightsString !== this.allFlightsString ||
      filtersString !== this.filtersString) {
      if (allFlights?.length && allFlights?.length < 20) {
        filters = {
          features: {},
          locations: {},
        };
      }
      this.setState({
        filteredFlights: getFlightRows(filters, this.props.allFlights, this.props.weather),
      })
      this.allFlightsString = allFlightsString;
      this.filtersString = filtersString;
    }
  }

  render() {
    const { allFlights, loadingFlights, filters, setFilter } = this.props;
    const { filteredFlights } = this.state;

    if (allFlights === null) {
      return null;
    }

    if (allFlights.length === 0 && !loadingFlights) {
      return <WarningText>No flights found</WarningText>;
    }

    const warmClass = filters.features.warmDestinations ? "enabled" : "disabled";
    const americaClass = filters.locations.america ? "enabled" : "disabled";
    const asiaClass = filters.locations.asia ? "enabled" : "disabled";
    const europeClass = filters.locations.europe ? "enabled" : "disabled";
    const finlandClass = filters.locations.finland ? "enabled" : "disabled";

    return loadingFlights ? (
      <Loader />
    ) : (
      <>
        <FilterList>
        <FilterGroup>
        { allFlights.length > 20 && <Filter className={warmClass} onClick={() => {setFilter('features', 'warmDestinations')}}>Warm Destinations</Filter> }
        </FilterGroup>
        <FilterGroup>
        { allFlights.length > 20 && <Filter className={americaClass} onClick={() => {setFilter('locations', 'america')}}>America</Filter> }
        { allFlights.length > 20 && <Filter className={asiaClass} onClick={() => {setFilter('locations', 'asia')}}>Asia</Filter> }
        { allFlights.length > 20 && <Filter className={europeClass} onClick={() => {setFilter('locations', 'europe')}}>Europe</Filter> }
        { allFlights.length > 20 && <Filter className={finlandClass} onClick={() => {setFilter('locations', 'finland')}}>Finland</Filter> }
        </FilterGroup>
        </FilterList>
        <ReactTable
          data={filteredFlights}
          columns={columns}
          showPagination={false}
          defaultPageSize={10000}
          minRows={0}
          onSortedChange={this.props.handleResetRows}
          noDataText={'No flights found'}
          expanded={this.props.expandedRows}
          // Sort by availability, if the list is long (only departure or arrival is selected)
          // Sort by departure time, if the list is short (both departure and arrival are selected)
          defaultSorted={
            allFlights.length > 20 &&
            [
            {
              id: 'availability',
              desc: true,
            },
          ] ||
          [
            {
              id: 'departure',
              desc: false,
            },
          ]
          }
          SubComponent={(row: any) => {
            const flight: TableItem = row.original;
            return flight ? <FlightDetails flight={flight} /> : <div>Not found</div>;
          }}
          getTrGroupProps={(state: any, rowInfo?: RowInfo) => {
            return {
              style: {
                boxShadow: boxShadow.grayBorders,
                borderLeft: `6px solid ${getBorderColor(rowInfo)}`,
                margin: `${staticScale.spacing.xxs} 0`,
                cursor: 'pointer',
              },
              onClick: () => {
                this.expandRow(rowInfo);
              },
            };
          }}
          getTheadProps={(state: any, rowInfo?: RowInfo) => {
            return {
              style: {
                border: 'none',
                boxShadow: 'none',
              },
            };
          }}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightList);
