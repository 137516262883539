const airportCodeNameMapping: Map<string, string> = new Map([
  ['GZP', 'Alanya'],
  ['ALC', 'Alicante'],
  ['AMS', 'Amsterdam'],
  ['AYT', 'Antalya'],
  // ['TSE', 'Astana'],
  // ['ATH', 'Athens'],
  ['BKK', 'Bangkok'],
  ['BCN', 'Barcelona'],
  ['BJS', 'Beijing'],
  ['PEK', 'Beijing'],
  ['BGO', 'Bergen'],
  ['BER', 'Berlin'],
  ['TXL', 'Berlin'],
  // ['BIQ', 'Biarritz'],
  ['BLL', 'Billund'],
  ['BOO', 'Bodø'],
  ['BRU', 'Brussels'],
  ['BUD', 'Budapest'],
  // ['PUS', 'Busan'],
  ['CTA', 'Catania'],
  ['CHQ', 'Chania'],
  ['ORD', 'Chicago'],
  // ['CKG', 'Chongqing'],
  ['CPH', 'Copenhagen'],
  ['CFU', 'Corfu'],
  ['DLM', 'Dalaman'],
  ['DFW', 'Dallas'],
  ['DEL', 'Delhi'],
  ['DOH', 'Doha'],
  ['DXB', 'Dubai'],
  ['DUB', 'Dublin'],
  ['DBV', 'Dubrovnik'],
  ['DUS', 'Duesseldorf'],
  ['EDI', 'Edinburgh'],
  ['VDA', 'Eilat/Ovda'],
  // ['SVX', 'Ekaterinburg'],
  // ['ENF', 'Enontekiö'],
  ['FAO', 'Faro'],
  ['FRA', 'Frankfurt'],
  ['FUE', 'Fuerteventure'],
  ['FUK', 'Fukuoka'],
  ['FNC', 'Funchal'],
  ['GDN', 'Gdansk'],
  ['GVA', 'Geneva'],
  // ['GOI', 'Goa'],
  ['GOT', 'Gothenburg'],
  ['LPA', 'Gran Canaria'],
  // ['CAN', 'Guangzhou'],
  ['HAM', 'Hamburg'],
  // ['HAV', 'Havanna'],
  ['HEL', 'Helsinki'],
  ['HER', 'Heraklion'],
  // ['SGN', 'Ho Chi Minh City'],
  ['HKG', 'Hong Kong'],
  ['IBZ', 'Ibiza'],
  ['INN', 'Innsbruck'],
  ['IVL', 'Ivalo'],
  ['JOE', 'Joensuu'],
  ['JYV', 'Jyvaskyla'],
  ['KAJ', 'Kajaani'],
  // ['KZN', 'Kazan'],
  ['KEM', 'Kemi'],
  ['KTT', 'Kittilä'],
  ['KOK', 'Kokkola/Pietarsaari'],
  ['KGS', 'Kos'],
  // ['KBV', 'Krabi'],
  ['KRK', 'Krakow'],
  ['KUO', 'Kuopio'],
  ['KAO', 'Kuusamo'],
  ['ACE', 'Lanzarote'],
  ['LCA', 'Larnaca'],
  ['LIS', 'Lisbon'],
  ['LJU', 'Ljubljana'],
  ['LON', 'London'],
  ['LHR', 'London'],
  ['LAX', 'Los Angeles'],
  // ['LLA', 'Lulea'],
  ['MHQ', 'Maarianhamina'],
  ['MAD', 'Madrid'],
  ['AGP', 'Malaga'],
  ['MMX', 'Malmö'],
  ['MLA', 'Malta'],
  ['MAN', 'Manchester'],
  ['MAH', 'Menorca'],
  ['MIA', 'Miami'],
  ['LIN', 'Milan (LIN)'],
  ['MIL', 'Milan'],
  ['MXP', 'Milan (MXP)'],
  // ['MSQ', 'Minsk'],
  // ['MOW', 'Moscow'],
  // ['SVO', 'Moscow'],
  ['BOM', 'Mumbai'],
  ['MUC', 'Munich'],
  // ['MJT', 'Mytilini'],
  ['NGO', 'Nagoya'],
  // ['NKG', 'Nanjing'],
  ['NAP', 'Napoli'],
  ['NYC', 'New York'],
  ['JFK', 'New York'],
  ['NCE', 'Nice'],
  // ['GOJ', 'Nizhny Novgorod'],
  // ['NRK', 'Norrkoeping'],
  ['OSA', 'Osaka'],
  ['KIX', 'Osaka'],
  ['OSL', 'Oslo'],
  ['OUL', 'Oulu'],
  ['PFO', 'Pafos'],
  ['PMI', 'Palma de Mallorca'],
  ['PAR', 'Paris'],
  ['CDG', 'Paris'],
  ['HKT', 'Phuket'],
  ['PSA', 'Pisa'],
  ['PRG', 'Prague'],
  ['PVK', 'Preveza'],
  ['OPO', 'Porto'],
  // ['POP', 'Puerto Plata'],
  // ['PVR', 'Puerto Vallarta'],
  ['PUY', 'Pula'],
  ['KEF', 'Reykjavik'],
  ['RHO', 'Rhodos'],
  ['RIX', 'Riga'],
  ['RMI', 'Rimini'],
  ['ROM', 'Rome'],
  ['FCO', 'Rome'],
  ['RVN', 'Rovaniemi'],
  ['SZG', 'Salzburg'],
  // ['KUF', 'Samara'],
  ['SFO', 'San Francisco'],
  ['JTR', 'Santorini'],
  ['SEA', 'Seattle'],
  ['SEL', 'Seoul (SEL)'],
  ['ICN', 'Seoul (ICN)'],
  ['SHA', 'Shanghai'],
  ['PVG', 'Shanghai'],
  ['SIN', 'Singapore'],
  ['JSI', 'Skiathos'],
  ['SPU', 'Split'],
  // ['LED', 'St.Petersburg'],
  // ['SVG', 'Stavanger'],
  ['STO', 'Stockholm'],
  ['ARN', 'Stockholm'],
  ['BMA', 'Stockholm'],
  // ['STR', 'Stuttgart'],
  ['TLL', 'Tallinn'],
  ['TMP', 'Tampere'],
  ['TAY', 'Tartu'],
  ['TLV', 'Tel Aviv'],
  ['TFN', 'Tenerife (TFN)'],
  ['TFS', 'Tenerife (TFS)'],
  ['TCI', 'Tenerife'],
  ['TRD', 'Trondheim'],
  ['TYO', 'Tokyo'],
  ['HND', 'Tokyo (HND)'],
  ['NRT', 'Tokyo (NRT)'],
  // ['YTO', 'Toronto'],
  ['TOS', 'Tromso'],
  ['TKU', 'Turku'],
  // ['UME', 'Umea'],
  ['VAA', 'Vaasa'],
  ['VAR', 'Varna'],
  ['VCE', 'Venice'],
  ['VRN', 'Verona'],
  ['VIE', 'Vienna'],
  ['VNO', 'Vilnius'],
  ['VBY', 'Visby'],
  ['WAW', 'Warsaw'],
  ['WRO', 'Wroclaw'],
  // ['XIY', 'Xian Xianyang'],
  ['ZTH', 'Zakinthos'],
  ['ZAG', 'Zagreb'],
  ['ZRH', 'Zurich'],
]);

export function getAirportNameByCode(airportCode: string): string {
  return airportCodeNameMapping.get(airportCode) ?? 'NO-AIRPORT-NAME';
}

export const destinations = [
  { value: 'GZP', label: 'Alanya' },
  { value: 'ALC', label: 'Alicante' },
  { value: 'AMS', label: 'Amsterdam' },
  { value: 'AYT', label: 'Antalya' },
  // { value: 'TSE', label: 'Astana' },
  // { value: 'ATH', label: 'Athens' },
  { value: 'BKK', label: 'Bangkok' },
  { value: 'BCN', label: 'Barcelona' },
  { value: 'PEK', label: 'Beijing' },
  { value: 'BGO', label: 'Bergen' },
  { value: 'BER', label: 'Berlin' },
  // { value: 'BIQ', label: 'Biarritz' },
  { value: 'BLL', label: 'Billund' },
  { value: 'BLQ', label: 'Bologna' },
  { value: 'BOD', label: 'Bordeaux' },
  { value: 'BOO', label: 'Bodø' },
  { value: 'BRU', label: 'Brussels' },
  { value: 'BUD', label: 'Budapest' },
  // { value: 'PUS', label: 'Busan' },
  { value: 'CTA', label: 'Catania' },
  { value: 'CHQ', label: 'Chania' },
  { value: 'ORD', label: 'Chicago' },
  // { value: 'CKG', label: 'Chongqing' },
  { value: 'CPH', label: 'Copenhagen' },
  { value: 'CFU', label: 'Corfu' },
  { value: 'DLM', label: 'Dalaman' },
  { value: 'DFW', label: 'Dallas' },
  { value: 'DEL', label: 'Delhi' },
  { value: 'DOH', label: 'Doha' },
  { value: 'DXB', label: 'Dubai' },
  { value: 'DUB', label: 'Dublin' },
  { value: 'DBV', label: 'Dubrovnik' },
  { value: 'DUS', label: 'Duesseldorf' },
  { value: 'EDI', label: 'Edinburgh' },
  { value: 'VDA', label: 'Eilat/Ovda' },
  // { value: 'SVX', label: 'Ekaterinburg' },
  // { value: 'ENF', label: 'Enontekiö' },
  { value: 'FAO', label: 'Faro' },
  { value: 'FRA', label: 'Frankfurt' },
  { value: 'FUE', label: 'Fuerteventure' },
  { value: 'FUK', label: 'Fukuoka' },
  { value: 'FNC', label: 'Funchal' },
  { value: 'GDN', label: 'Gdansk' },
  { value: 'GVA', label: 'Geneva' },
  // { value: 'GOI', label: 'Goa' },
  { value: 'GOT', label: 'Gothenburg' },
  { value: 'LPA', label: 'Gran Canaria' },
  // { value: 'CAN', label: 'Guangzhou' },
  { value: 'HAM', label: 'Hamburg' },
  // { value: 'HAJ', label: 'Hannover' },
  { value: 'HAV', label: 'Havanna' },
  { value: 'HEL', label: 'Helsinki' },
  { value: 'HER', label: 'Heraklion' },
  // { value: 'SGN', label: 'Ho Chi Minh City' },
  { value: 'HKG', label: 'Hong Kong' },
  { value: 'IBZ', label: 'Ibiza' },
  { value: 'INN', label: 'Innsbruck' },
  { value: 'IVL', label: 'Ivalo' },
  { value: 'JOE', label: 'Joensuu' },
  { value: 'JYV', label: 'Jyvaskyla' },
  { value: 'KAJ', label: 'Kajaani' },
  // { value: 'KZN', label: 'Kazan' },
  { value: 'KEM', label: 'Kemi' },
  { value: 'KTT', label: 'Kittilä' },
  { value: 'KOK', label: 'Kokkola/Pietarsaari' },
  { value: 'KGS', label: 'Kos' },
  // { value: 'KBV', label: 'Krabi' },
  { value: 'KRK', label: 'Krakow' },
  { value: 'KUO', label: 'Kuopio' },
  { value: 'KAO', label: 'Kuusamo' },
  { value: 'ACE', label: 'Lanzarote' },
  { value: 'LCA', label: 'Larnaca' },
  { value: 'LIS', label: 'Lisbon' },
  { value: 'LJU', label: 'Ljubljana' },
  { value: 'LHR', label: 'London' },
  { value: 'LAX', label: 'Los Angeles' },
  // { value: 'LLA', label: 'Lulea' },
  { value: 'LYS', label: 'Lyon' },
  { value: 'MHQ', label: 'Maarianhamina' },
  { value: 'MAD', label: 'Madrid' },
  { value: 'AGP', label: 'Malaga' },
  { value: 'MLA', label: 'Malta' },
  { value: 'MAN', label: 'Manchester' },
  { value: 'MAH', label: 'Menorca' },
  { value: 'MIA', label: 'Miami' },
  { value: 'LIN', label: 'Milan (LIN)' },
  { value: 'MXP', label: 'Milan (MXP)' },
  // { value: 'MSQ', label: 'Minsk' },
  // { value: 'MOW', label: 'Moscow' },
  // { value: 'SVO', label: 'Moscow' },
  { value: 'BOM', label: 'Mumbai' },
  { value: 'MUC', label: 'Munich' },
  // { value: 'MJT', label: 'Mytilini' },
  { value: 'NGO', label: 'Nagoya' },
  // { value: 'NKG', label: 'Nanjing' },
  { value: 'NAP', label: 'Napoli' },
  { value: 'JFK', label: 'New York' },
  { value: 'NCE', label: 'Nice' },
  // { value: 'GOJ', label: 'Nizhny Novgorod' },
  // { value: 'NRK', label: 'Norrkoeping' },
  { value: 'KIX', label: 'Osaka' },
  { value: 'OSL', label: 'Oslo' },
  { value: 'OUL', label: 'Oulu' },
  { value: 'PFO', label: 'Pafos' },
  { value: 'PMI', label: 'Palma de Mallorca' },
  { value: 'CDG', label: 'Paris' },
  { value: 'HKT', label: 'Phuket' },
  { value: 'PSA', label: 'Pisa' },
  { value: 'PRG', label: 'Prague' },
  { value: 'PVK', label: 'Preveza' },
  { value: 'OPO', label: 'Porto' },
  // { value: 'POP', label: 'Puerto Plata' },
  // { value: 'PVR', label: 'Puerto Vallarta' },
  { value: 'PUY', label: 'Pula' },
  { value: 'PUJ', label: 'Punta Cana' },
  { value: 'KEF', label: 'Reykjavik' },
  { value: 'RHO', label: 'Rhodos' },
  { value: 'RIX', label: 'Riga' },
  { value: 'RMI', label: 'Rimini' },
  { value: 'FCO', label: 'Rome' },
  { value: 'RVN', label: 'Rovaniemi' },
  { value: 'SZG', label: 'Salzburg' },
  // { value: 'KUF', label: 'Samara' },
  { value: 'SFO', label: 'San Francisco' },
  { value: 'JTR', label: 'Santorini' },
  // { value: 'CTS', label: 'Sapporo' },
  { value: 'SEA', label: 'Seattle' },
  { value: 'ICN', label: 'Seoul' },
  { value: 'PVG', label: 'Shanghai' },
  { value: 'SIN', label: 'Singapore' },
  { value: 'JSI', label: 'Skiathos' },
  { value: 'SPU', label: 'Split' },
  // { value: 'LED', label: 'St.Petersburg' },
  // { value: 'SVG', label: 'Stavanger' },
  { value: 'ARN', label: 'Stockholm (ARN)' },
  { value: 'BMA', label: 'Stockholm (BMA)' },
  // { value: 'STR', label: 'Stuttgart' },
  { value: 'TLL', label: 'Tallinn' },
  { value: 'TMP', label: 'Tampere' },
  { value: 'TAY', label: 'Tartu' },
  { value: 'TLV', label: 'Tel Aviv' },
  { value: 'TFN', label: 'Tenerife (TFN)' },
  { value: 'TFS', label: 'Tenerife (TFS)' },
  { value: 'NRT', label: 'Tokyo (NRT)' },
  { value: 'HND', label: 'Tokyo (HND)' },
  // { value: 'YTO', label: 'Toronto' },
  { value: 'TOS', label: 'Tromso' },
  { value: 'TRD', label: 'Trondheim' },
  { value: 'TKU', label: 'Turku' },
  // { value: 'UME', label: 'Umea' },
  { value: 'VAA', label: 'Vaasa' },
  { value: 'VAR', label: 'Varna' },
  { value: 'VCE', label: 'Venice' },
  { value: 'VRN', label: 'Verona' },
  { value: 'VIE', label: 'Vienna' },
  { value: 'VNO', label: 'Vilnius' },
  { value: 'VBY', label: 'Visby' },
  { value: 'WAW', label: 'Warsaw' },
  { value: 'WRO', label: 'Wroclaw' },
  // { value: 'XIY', label: 'Xian Xianyang' },
  { value: 'ZTH', label: 'Zakinthos' },
  { value: 'ZAG', label: 'Zagreb' },
  { value: 'ZRH', label: 'Zurich' },
];
