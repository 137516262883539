export const WARM_DESTINATION_THRESHOLD = 20;

export const ALL_MONTHS = 100;
export const WARM_DESTINATIONS: { [key: string]: number[] } = {
  'GZP': [4, 5, 6, 7, 8, 9, 10, 11],
  'ALC': [3, 4, 5, 6, 7, 8, 9, 10, 11],
  'AMS': [5, 6, 7, 8, 9, 10],
  'AYT': [4, 5, 6, 7, 8, 9, 10, 11],
  'TSE': [5, 6, 7, 8],
  'ATH': [4, 5, 6, 7, 8, 9, 10],
  'BKK': [ALL_MONTHS],
  'BCN': [4, 5, 6, 7, 8, 9, 10],
  'BJS': [4, 5, 6, 7, 8, 9, 10, 11],
  'PEK': [4, 5, 6, 7, 8, 9, 10, 11],
  'BGO': [7],
  'BOO': [],
  'BER': [5, 6, 7, 8, 9],
  'TXL': [5, 6, 7, 8, 9],
  'BIQ': [5, 6, 7, 8, 9],
  'BLL': [6, 7, 8],
  'BLQ': [5, 6, 7, 8],
  'BRU': [6, 7, 8, 9],
  'BUD': [5, 6, 7, 8, 9],
  'PUS': [5, 6, 7, 8, 9, 10],
  'CTA': [4, 5, 6, 7, 8, 9, 10, 11],
  'CHQ': [5, 6, 7, 8, 9, 10],
  'ORD': [5, 6, 7, 8, 9],
  'CKG': [4, 5, 6, 7, 8, 9, 10],
  'CPH': [6, 7, 8],
  'CFU': [4, 5, 6, 7, 8, 9, 10],
  'DLM': [4, 5, 6, 7, 8, 9, 10, 11],
  'DFW': [4, 5, 6, 7, 8, 9, 10, 11],
  'DEL': [ALL_MONTHS],
  'DXB': [ALL_MONTHS],
  'DUB': [7, 8],
  'DBV': [5, 6, 7, 8, 9, 10],
  'DUS': [5, 6, 7, 8, 9],
  'EDI': [7, 8],
  'VDA': [ALL_MONTHS],
  // 'SVX': [6,7,8],
  'ENF': [7, 8],
  'FAO': [4, 5, 6, 7, 8, 9, 10, 11],
  'FRA': [5, 6, 7, 8, 9],
  'FUE': [ALL_MONTHS],
  'FUK': [4, 5, 6, 7, 8, 9],
  'FNC': [ALL_MONTHS],
  'GDN': [6, 7, 8],
  'GVA': [5, 6, 7, 8, 9],
  'GOI': [ALL_MONTHS],
  'GOT': [6, 7, 8],
  'LPA': [ALL_MONTHS],
  'CAN': [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  'HAM': [6, 7, 8],
  'HAV': [ALL_MONTHS],
  'HEL': [7, 8],
  'HER': [4, 5, 6, 7, 8, 9, 10, 11],
  'SGN': [ALL_MONTHS],
  'HKG': [4, 5, 6, 7, 8, 9, 10, 11],
  'IBZ': [4, 5, 6, 7, 8, 9, 10, 11],
  'INN': [5, 6, 7, 8, 9],
  'IVL': [7],
  'JOE': [7, 8],
  'JYV': [7, 8],
  'KAJ': [7],
  // 'KZN': [5,6,7],
  'KEM': [7],
  'KTT': [7],
  'KOK': [7, 8],
  'KGS': [4, 5, 6, 7, 8, 9, 10, 11],
  'KBV': [ALL_MONTHS],
  'KRK': [5, 6, 7, 8, 9],
  'KUO': [7, 8],
  'KAO': [7],
  'ACE': [ALL_MONTHS],
  'LCA': [3, 4, 5, 6, 7, 8, 9, 10, 11],
  'LAX': [ALL_MONTHS],
  'LIS': [4, 5, 6, 7, 8, 9],
  'LJU': [5, 6, 7, 8, 9],
  'LON': [6, 7, 8, 9],
  'LHR': [6, 7, 8, 9],
  'LIN': [5, 6, 7, 8, 9],
  'LLA': [7],
  'MHQ': [7, 8],
  'MAD': [5, 6, 7, 8, 9],
  'AGP': [3, 4, 5, 6, 7, 8, 9, 10, 11],
  'MMX': [6, 7, 8],
  'MLA': [4, 5, 6, 7, 8, 9, 10, 11],
  'MAN': [7, 8],
  'MAH': [5, 6, 7, 8, 9],
  'MIA': [ALL_MONTHS],
  'MIL': [5, 6, 7, 8, 9],
  'MXP': [5, 6, 7, 8, 9],
  // 'MSQ': [6,7,8],
  // 'MOW': [6,7,8],
  // 'SVO': [6,7,8],
  'BOM': [ALL_MONTHS],
  'MUC': [5, 6, 7, 8, 9],
  'MJT': [4, 5, 6, 7, 8, 9],
  'NGO': [4, 5, 6, 7, 8, 9],
  'NKG': [4, 5, 6, 7, 8, 9, 10],
  'NAP': [5, 6, 7, 8, 9],
  'NYC': [5, 6, 7, 8, 9],
  'JFK': [5, 6, 7, 8, 9],
  'NCE': [5, 6, 7, 8, 9],
  // 'GOJ': [5,6,7,8],
  'NRK': [6, 7, 8],
  'OSA': [4, 5, 6, 7, 8, 9, 10],
  'KIX': [4, 5, 6, 7, 8, 9, 10],
  'OSL': [6, 7, 8],
  'OUL': [7, 8],
  'PFO': [4, 5, 6, 7, 8, 9, 10, 11],
  'PMI': [4, 5, 6, 7, 8, 9, 10, 11],
  'PAR': [5, 6, 7, 8, 9],
  'CDG': [5, 6, 7, 8, 9],
  'HKT': [ALL_MONTHS],
  'PSA': [5, 6, 7, 8, 9, 10],
  'PRG': [5, 6, 7, 8, 9],
  'PVK': [4, 5, 6, 7, 8, 9, 10],
  'POP': [ALL_MONTHS],
  'PVR': [ALL_MONTHS],
  'PUY': [5, 6, 7, 8, 9, 10],
  'DOH': [ALL_MONTHS],
  'KEF': [],
  'RHO': [4, 5, 6, 7, 8, 9, 10],
  'RIX': [6, 7, 8],
  'RMI': [5, 6, 7, 8, 9, 10],
  'ROM': [5, 6, 7, 8, 9, 10],
  'FCO': [5, 6, 7, 8, 9, 10],
  'RVN': [7],
  'SZG': [5, 6, 7, 8, 9],
  'KUF': [5, 6, 7, 8, 9],
  'SFO': [6, 7, 8, 9],
  'JTR': [5, 6, 7, 8, 9, 10, 11],
  'SEA': [5, 6, 7, 8, 9],
  'SEL': [5, 6, 7, 8, 9, 10],
  'ICN': [5, 6, 7, 8, 9, 10],
  'SHA': [4, 5, 6, 7, 8, 9, 10],
  'PVG': [4, 5, 6, 7, 8, 9, 10],
  'SIN': [ALL_MONTHS],
  'JSI': [5, 6, 7, 8, 9],
  'SPU': [5, 6, 7, 8, 9, 10],
  // 'LED': [6,7,8],
  'SVG': [],
  'STO': [6, 7, 8],
  'ARN': [6, 7, 8],
  'BMA': [6, 7, 8],
  'STR': [6, 7, 8, 9],
  'TLL': [6, 7, 8],
  'TMP': [7, 8],
  'TAY': [6, 7, 8],
  'TLV': [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  'TCI': [ALL_MONTHS],
  'TFN': [ALL_MONTHS],
  'TFS': [ALL_MONTHS],
  'TRD': [],
  'TYO': [4, 5, 6, 7, 8, 9, 10],
  'HND': [4, 5, 6, 7, 8, 9, 10],
  'NRT': [4, 5, 6, 7, 8, 9, 10],
  'YTO': [6, 7, 8, 9],
  'TOS': [],
  'TKU': [7, 8],
  'UME': [7, 8],
  'VAA': [7, 8],
  'VAR': [5, 6, 7, 8, 9, 10],
  'VCE': [5, 6, 7, 8, 9],
  'VRN': [5, 6, 7, 8, 9],
  'VIE': [5, 6, 7, 8, 9],
  'VNO': [6, 7, 8],
  'VBY': [5, 6, 7, 8, 9],
  'WAW': [5, 6, 7, 8, 9],
  'WRO': [5, 6, 7, 8, 9],
  'XIY': [4, 5, 6, 7, 8, 9, 10],
  'ZTH': [5, 6, 7, 8, 9, 10],
  'ZAG': [5, 6, 7, 8, 9],
  'ZRH': [5, 6, 7, 8, 9],
};
