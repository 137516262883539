import {
  AvailabilityActionTypes,
  EXPAND_ROW_ACTION,
  ExpandRowAction,
  FETCH_AVAILABILITY_FAIL,
  FETCH_AVAILABILITY_START,
  FETCH_AVAILABILITY_SUCCESS,
  FETCH_DATES_FAIL,
  FETCH_DATES_START,
  FETCH_DATES_SUCCESS,
  FETCH_FLIGHTS_FAIL,
  FETCH_FLIGHTS_START,
  FETCH_FLIGHTS_SUCCESS,
  FETCH_WEATHER_FAIL,
  FETCH_WEATHER_START,
  FETCH_WEATHER_SUCCESS,
  FetchAvailabilityFailAction,
  FetchAvailabilityStartAction,
  FetchAvailabilitySuccessAction,
  FetchDatesFailAction,
  FetchDatesStartAction,
  FetchDatesSuccessAction,
  FetchFlightsFailAction,
  FetchFlightsStartAction,
  FetchFlightsSuccessAction,
  FetchWeatherFailAction,
  FetchWeatherStartAction,
  FetchWeatherSuccessAction,
  FILTER_FLIGHTS_ACTION,
  FilterFlightsAction,
  RESET_EXPANDED_ROWS_ACTION,
  ResetExpandedRowsAction,
  SELECT_ARRIVAL_CITY_ACTION,
  SELECT_DEPARTURE_CITY_ACTION,
  SELECT_DEPARTURE_DATE_ACTION,
  SELECT_REGION_FILTER_ACTION,
  SelectArrivalCityAction,
  SelectDepartureCityAction,
  SelectDepartureDateAction,
  SelectFilterAction,
  SWITCH_CITIES_ACTION,
  SwitchCitiesAction,
} from '../actions/availability.actions';
import { createReducer } from '../../utils/createReducer';
import { City, Flight, IdTravellersAndSeats, IdTravellersAndSeatsForFlight, Weather } from '../model/interfaces';
import { isNotPresent, isPresent } from '../../utils/typeUtils';
import Cookies from 'js-cookie';

interface AvailabilityState {
  selectedDepartureDate: Date | null;
  selectedDepartureCity: City | null;
  selectedArrivalCity: City | null;
  flights: Flight[];
  availability: { [s: string]: { [s: string]: IdTravellersAndSeats } };
  availableDays: string[];
  allFilteredFlights: Flight[];
  loadingFlights: boolean;
  flightDetails: { [s: string]: { [s: string]: IdTravellersAndSeatsForFlight } };
  filters: { [region: string]: boolean };
  expandedRows: { [id: number]: boolean };
  weather: { [city: string]: Weather[] };
}

interface AvailabilityErrorState {
  error: string | null;
}

export interface State extends AvailabilityState, AvailabilityErrorState {}

const createInitialAvailabilityState = (): AvailabilityState => {
  const savedDepartureDate = Cookies.get('departureDate');
  const savedDepartureCity = Cookies.get('departureCity');
  const savedArrivalCity = Cookies.get('arrivalCity');

  return {
    selectedDepartureDate: isPresent(savedDepartureDate) ? new Date(savedDepartureDate) : new Date(),
    selectedDepartureCity: isPresent(savedDepartureCity)
      ? JSON.parse(savedDepartureCity)
      : { code: 'HEL', name: 'Helsinki' },
    selectedArrivalCity: isPresent(savedArrivalCity) ? JSON.parse(savedArrivalCity) : null,
    flights: [],
    availability: {},
    availableDays: [],
    allFilteredFlights: [],
    loadingFlights: true,
    flightDetails: {},
    filters: {
      america: true,
      europe: true,
      finland: true,
      other: true,
      asia: true,
    },
    expandedRows: {},
    weather: {},
  };
};

const createInitialErrorState = (): AvailabilityErrorState => {
  return {
    error: null,
  };
};

export const createInitialState = (): State => {
  return {
    ...createInitialAvailabilityState(),
    ...createInitialErrorState(),
  };
};

const initialState: State = createInitialState();

export default createReducer<State, AvailabilityActionTypes>(initialState, {
  [SELECT_DEPARTURE_CITY_ACTION]: (state, action: SelectDepartureCityAction) => ({
    ...state,
    selectedDepartureCity: action.payload,
  }),
  [SELECT_ARRIVAL_CITY_ACTION]: (state, action: SelectArrivalCityAction) => ({
    ...state,
    selectedArrivalCity: action.payload,
  }),
  [SWITCH_CITIES_ACTION]: (state, action: SwitchCitiesAction) => ({
    ...state,
    selectedArrivalCity: state.selectedDepartureCity,
    selectedDepartureCity: state.selectedArrivalCity,
  }),
  [SELECT_DEPARTURE_DATE_ACTION]: (state, action: SelectDepartureDateAction) => ({
    ...state,
    selectedDepartureDate: action.payload,
  }),
  [FETCH_FLIGHTS_SUCCESS]: (state, action: FetchFlightsSuccessAction) => ({
    ...state,
    flights: action.payload ? action.payload : [],
    loadingFlights: false,
  }),
  [FETCH_FLIGHTS_FAIL]: (state, action: FetchFlightsFailAction) => ({
    ...state,
    loadingFlights: false,
  }),
  [FETCH_FLIGHTS_START]: (state, action: FetchFlightsStartAction) => ({
    ...state,
    loadingFlights: true,
  }),
  [FETCH_AVAILABILITY_SUCCESS]: (state, action: FetchAvailabilitySuccessAction) => ({
    ...state,
    availability: action.payload ? action.payload : {},
  }),
  [FETCH_AVAILABILITY_FAIL]: (state, action: FetchAvailabilityFailAction) => ({
    ...state,
  }),
  [FETCH_AVAILABILITY_START]: (state, action: FetchAvailabilityStartAction) => ({
    ...state,
  }),
  [FETCH_DATES_SUCCESS]: (state, action: FetchDatesSuccessAction) => ({
    ...state,
    availableDays: action.payload ? action.payload : [],
  }),
  [FETCH_DATES_FAIL]: (state, action: FetchDatesFailAction) => ({
    ...state,
  }),
  [FETCH_DATES_START]: (state, action: FetchDatesStartAction) => ({
    ...state,
  }),
  [FILTER_FLIGHTS_ACTION]: (state, action: FilterFlightsAction) => ({
    ...state,
    allFilteredFlights: action.payload,
  }),
  [SELECT_REGION_FILTER_ACTION]: (state, action: SelectFilterAction) => ({
    ...state,
    filters: {
      ...state.filters,
      [action.payload]: !state.filters[action.payload],
    },
  }),
  [EXPAND_ROW_ACTION]: (state, action: ExpandRowAction) => ({
    ...state,
    expandedRows: {
      ...state.expandedRows,
      [action.payload]: isNotPresent(state.expandedRows[action.payload]) || !state.expandedRows[action.payload],
    },
  }),
  [RESET_EXPANDED_ROWS_ACTION]: (state, action: ResetExpandedRowsAction) => ({
    ...state,
    expandedRows: {},
  }),
  [FETCH_WEATHER_SUCCESS]: (state, action: FetchWeatherSuccessAction) => ({
    ...state,
    weather: action.payload ? action.payload : {},
  }),
  [FETCH_WEATHER_FAIL]: (state, action: FetchWeatherFailAction) => ({
    ...state,
  }),
  [FETCH_WEATHER_START]: (state, action: FetchWeatherStartAction) => ({
    ...state,
  }),
});
