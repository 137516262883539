import React from 'react';
import styled from 'styled-components';
import { color, scale, weight } from '../../../styles';
import { InlineSvgIcon } from '../../InlineSvgIcon';

interface Props {
  duration: string;
}

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Duration = styled.div`
  font-size: ${scale.font.xxs};
  line-height: ${scale.lineHeight.xxs};
  font-weight: ${weight.regular};
  color: ${color.darkGray};
`;

const IconWrapper = styled.div`
  font-size: ${scale.font.l};
  line-height: ${scale.lineHeight.l};
  height: ${scale.font.xl};
  margin-top: 2px;
`;
const Icon = styled(InlineSvgIcon)`
  color: ${color.darkGray};
  svg {
    height: 24px;
  }
`;

export class ColumnDuration extends React.Component<Props> {
  render() {
    return (
      <Wrapper>
        <IconWrapper>
          <Icon name="oneway" />
        </IconWrapper>
        <Duration>{this.props.duration}</Duration>
      </Wrapper>
    );
  }
}
