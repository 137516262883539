import React from 'react';
import { IdTravellersAndSeatsForFlight } from '../../../model/interfaces';
import styled from 'styled-components';
import { color, scale, staticScale, weight } from '../../../styles';
import { isNotPresent } from '../../../../utils/typeUtils';
import { InlineSvgIcon } from '../../InlineSvgIcon';

interface Props {
  details: IdTravellersAndSeatsForFlight | null;
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Text = styled.span`
  font-size: ${scale.font.m};
  line-height: ${scale.lineHeight.m};
  font-weight: ${weight.book};
  color: ${color.darkGray};
  margin: auto;
`;
const SmallText = styled(Text)`
  font-size: ${scale.font.xxs};
  line-height: ${scale.lineHeight.xxs};
  font-weight: ${weight.book};
  color: ${color.darkGray};
  margin: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;
const Icon = styled(InlineSvgIcon)`
  display: flex;
  color: ${color.black};
  margin: ${staticScale.spacing.xs};
  svg {
    height: ${scale.icon.s};
  }
`;

export class FlightName extends React.Component<Props> {
  render() {
    const { details } = this.props;

    if (isNotPresent(details) || isNotPresent(details.flightNumber)) {
      return null;
    }
    return (
      <Wrapper>
        <IconWrapper>
          <Icon name="oneway" />
        </IconWrapper> 
        <TextWrapper>
          <Text>{details.flightNumber}</Text>
          <SmallText>{details.originCode}-{details.destinationCode}</SmallText>
          <SmallText>{details.aircraftType}</SmallText>
        </TextWrapper>
      </Wrapper>
    );
  }
}
