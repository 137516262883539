import React from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { connect } from 'react-redux';
import { AvailabilityThunkDispatch, RootState } from '../../../rootReducer';
import { AvailabilityActions } from '../../actions/availability.actions';
import './Calendar.css';
import styled from 'styled-components';
import moment from 'moment';
import { City } from '../../model/interfaces';

interface Props {
  selectedDate: Date | null;
  handleSelectDepartureDate: (date: Date | null) => void;
  availableDays: string[];
  arrivalAirport: City | null;
  departureAirport: City | null;
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedDate: state.availability.selectedDepartureDate,
    availableDays: state.availability.availableDays,
    arrivalAirport: state.availability.selectedArrivalCity,
    departureAirport: state.availability.selectedDepartureCity
  };
};

const mapDispatchToProps = (dispatch: AvailabilityThunkDispatch) => ({
  handleSelectDepartureDate: (date: Date | null) => {
    dispatch(AvailabilityActions.selectDepartureDate(date));
  },
});

const Container = styled.div`
  text-align: center;
`;

export class Calendar extends React.Component<Props> {
  handleDayClick = (date: Date) => {
    this.props.handleSelectDepartureDate(date);
  };

  isDisabledDay = (day: Date) => {
    return (
      this.props.arrivalAirport !== null && this.props.departureAirport !== null &&
      !this.props.availableDays.includes(moment(day).format('YYYY-MM-DD'))
    );
  };

  render() {
    return (
      <Container>
        <DayPicker
          weekStartsOn={1}
          selected={this.props.selectedDate || undefined}
          onDayClick={this.handleDayClick}
          modifiers={{ 'no-flights': this.isDisabledDay}}
          modifiersClassNames={{
            selected: 'day-picker-selected',
            'no-flights': 'day-picker-no-flights'
          }}
        />
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
