import * as React from 'react';
import { Component } from 'react';
import { CityPicker } from '../CityPicker/CityPicker';
import { City } from '../../model/interfaces';
import { AvailabilityThunkDispatch, RootState } from '../../../rootReducer';
import { AvailabilityActions } from '../../actions/availability.actions';
import { connect } from 'react-redux';
import Calendar from '../Calendar/Calendar';
import styled from 'styled-components';
import { color, scale, staticScale, weight } from '../../styles';
import { InlineSvgIcon } from '../InlineSvgIcon';
import { destinations as options } from '../../../utils/airport-utils';


interface Props {
  selectedDepartureCity: City | null;
  selectedArrivalCity: City | null;
  handleSelectDepartureCity: (city: City | null) => void;
  handleSelectArrivalCity: (city: City | null) => void;
  handleSwitchCities: () => void;
  initialiseFlights: () => void;
  initialiseWeather: () => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedDepartureCity: state.availability.selectedDepartureCity,
    selectedArrivalCity: state.availability.selectedArrivalCity,
  };
};

const mapDispatchToProps = (dispatch: AvailabilityThunkDispatch) => ({
  handleSelectDepartureCity: (city: City | null) => {
    dispatch(AvailabilityActions.selectDepartureCity(city));
  },
  handleSelectArrivalCity: (city: City | null) => {
    dispatch(AvailabilityActions.selectArrivalCity(city));
  },
  handleSwitchCities: () => {
    dispatch(AvailabilityActions.switchCities());
  },
  initialiseFlights: () => {
    dispatch(AvailabilityActions.initialiseFlights());
  },
  initialiseWeather: () => {
    dispatch(AvailabilityActions.createFetchWeatherAction());
  },
});

const Container = styled.div`
  align-items: center;
`;

const InfoHeader = styled.div`
  text-align: center;
  font-size: ${scale.font.m};
  color: ${color.blue};
  line-height: 2rem;
`;

const InfoContent = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

/*
These styles are used for announcements when necessary
const WarnContent = styled.div`
  color: red;
  margin-bottom: 1rem;
  text-align: center;
`;

const H = styled.div`
  font-weight: 500;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;

const P = styled.div`
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;
*/

const CityPickers = styled.div`
  display: flex;
`;

const DestinationWrapper = styled.div`
  flex-grow: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Text = styled.div`
  font-size: ${scale.font.s};
  line-height: ${scale.lineHeight.s};
  font-weight: ${weight.regular};
  color: ${color.darkGray};
  margin: ${scale.spacing.xxs} 0;
`;

const SwitchIcon = styled(InlineSvgIcon)`
  margin: ${staticScale.spacing.xxs};
  margin-bottom: ${staticScale.spacing.m};

  color: ${color.blue};

  height: 24px;
  svg {
    width: 24px;
  }
`;

export class FlightSearch extends Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.initialiseFlights();
    this.props.initialiseWeather();
  }

  render() {
    const {
      selectedArrivalCity,
      selectedDepartureCity,
      handleSelectArrivalCity,
      handleSelectDepartureCity,
      handleSwitchCities,
    } = this.props;

    const optionsWithAll = [{ value: 'XXX', label: 'All destinations' }].concat(options)

    return (
      <Container>
        <CityPickers>
          <DestinationWrapper>
            <Text>From</Text>
            <CityPicker
              handleSelectCity={handleSelectDepartureCity}
              isDeparture={true}
              options={selectedArrivalCity === null ? options : optionsWithAll}
              selectedCity={selectedDepartureCity}
            />
          </DestinationWrapper>
          <IconWrapper>
            <SwitchIcon name="switch" onClick={handleSwitchCities} />
          </IconWrapper>
          <DestinationWrapper>
            <Text>To</Text>
            <CityPicker
              handleSelectCity={handleSelectArrivalCity}
              isDeparture={false}
              options={selectedDepartureCity === null ? options : optionsWithAll}
              selectedCity={selectedArrivalCity}
            />
          </DestinationWrapper>
        </CityPickers>
        <Calendar />
        <InfoHeader>
          INFO
        </InfoHeader>
        <InfoContent>
          Click on a flight to get up-to-date availability and ID listings
        </InfoContent>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightSearch);
