import React from 'react';
import { IdTravellersAndSeatsForFlight, TableItem } from '../../../model/interfaces';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { scale } from '../../../styles';
import { FreePaxSeats } from './FreePaxSeats';
import { Listings } from './Listings';
import { FlightName } from './FlightName';
import { env } from '../../../../env';
import { getAccessToken } from 'App/Components/Auth/Auth';
import { FreePetSeats } from './FreePetSeats';

const getCurrentEnv = env.getCurrentEnv;

interface State {
  details: IdTravellersAndSeatsForFlight[] | null;
}

interface Props {
  flight: TableItem;
}

const fetchSingleFlightDetails = async (
  departureDate: Date | null,
  flightNumber: string,
  departureStation: string,
  arrivalStation: string
): Promise<IdTravellersAndSeatsForFlight[] | null> => {
  const body = {
    queries: [
      {
        flightDate: departureDate ? moment(departureDate).format('YYYY-MM-DD').toString() : null,
        carrier: flightNumber.substr(0, 2),
        flightNumber: flightNumber.substr(2),
        departureStation,
        arrivalStation,
      },
    ],
  };

  const headers = {
    Accept: 'application/json',
    Authorization: await getAccessToken(),
    'x-api-key': process.env.availabilityApiKey,
  };

  try {
    const response = await axios.post(`${getCurrentEnv().availability.url}/availability/details`, body, {
      headers: headers,
    });

    return response.data;
  } catch (errorResponse: any) {
    console.warn('NO DETAILS FOUND');
    if (errorResponse?.message === 'Network Error' || errorResponse?.response?.status === 403) {
      console.warn('SERVICE RESPONDED WITH 403: REFRESHING PAGE');
    }
    return null;
  }
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${scale.spacing.xs};
  padding-top: 0;
`;
const FreeSeatsWrapper = styled.div`
  display: flex;
`;

export class FlightDetails extends React.Component<Props, State> {
  state = {
    details: [],
  };

  UNSAFE_componentWillMount(): void {
    const { flight } = this.props;

    const departureDate: Date = moment(flight.departure.date).toDate();

    fetchSingleFlightDetails(departureDate, flight.flightName, flight.departure.airportCode, flight.arrival.airportCode).then((x) => {
      this.setState({
        details: x,
      });
    });
  }

  render() {
    const { details } = this.state;

    return details?.length > 0 ? details.map((detail: IdTravellersAndSeatsForFlight, i: number) => (
      <Wrapper key={i}>
        <FreeSeatsWrapper>
          <FlightName details={detail} />
          <FreePaxSeats details={detail} type="Business" />
          <FreePaxSeats details={detail} type="Premium" />
          <FreePaxSeats details={detail} type="Economy" />
          <FreePetSeats freeSeats={this.props.flight.seats} />
        </FreeSeatsWrapper>
        <Listings details={detail} />
      </Wrapper>
    )) : (
      <Loader />
    );
  }
}
