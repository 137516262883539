export const isNull = (obj: any): obj is null => obj === null && typeof obj === 'object';
export const isNotNull = <T>(obj: T | null): obj is T => !isNull(obj);

export const isUndefined = (obj: any): obj is undefined => typeof obj === 'undefined';
export const isNotUndefined = <T>(obj: T | undefined): obj is T => !isUndefined(obj);

export const isNotPresent = (obj: any): obj is null | undefined => isNull(obj) || isUndefined(obj);
export const isPresent = <T>(obj: T | undefined | null): obj is T => !isNotPresent(obj);

export const isArray = (arr: any): arr is any[] => {
  return Array.isArray(arr);
};

export const isObject = (obj: any): obj is object => isPresent(obj) && typeof obj === 'object';

export const isFunction = (func: any): func is Function => isPresent(func) && func instanceof Function;

export const isString = (obj: any): obj is string => isPresent(obj) && typeof obj === 'string';
export const isNumber = (obj: any): obj is number => isPresent(obj) && typeof obj === 'number';
