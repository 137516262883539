import React from 'react';
import { FreeSeats } from '../../../model/interfaces';
import styled from 'styled-components';
import { color, scale, weight } from '../../../styles';
import { InlineSvgIcon } from '../../InlineSvgIcon';

interface Props {
  freeSeats: FreeSeats | null;
};

const Wrapper = styled.div`
  width: 10%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: ${scale.spacing.xxs};
`;

const SeatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const Type = styled.div`
  font-size: ${scale.font.xs};
  line-height: ${scale.lineHeight.xs};
  margin: auto;
`;

const Seats = styled.div`
  display: flex;
  font-size: ${scale.font.xxs};
  line-height: ${scale.lineHeight.xxs};
`;

const Text = styled.span`
  line-height: ${scale.lineHeight.m};
  font-weight: ${weight.book};
  margin: auto;
`;

const Number = styled(Text)`
  font-size: ${scale.font.l};
  line-height: ${scale.lineHeight.s};
  font-weight: ${weight.book};
  margin: 6px auto;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Icon = styled(InlineSvgIcon)`
  margin: auto;

  color: ${color.seatGray};

  &.green {
    color: ${color.seatGreen};
  }
  &.yellow {
    color: ${color.seatYellow};
  }
  &.red {
    color: ${color.seatRed};
  }

  svg {
    height: ${scale.icon.s};
  }
`;

const getPetIconClass = (petSeats: number): string => {
  if (isNaN(petSeats)) return 'grey';
  if (petSeats > 1) return 'green';
  if (petSeats > 0) return 'yellow';
  return 'red';
};

export class FreePetSeats extends React.Component<Props> {
  render() {
    const freeSeats: FreeSeats = this.props.freeSeats ?? {
      seats: 0,
      petSeats: 0,
      timestamp: "",
    };

    return (
      <Wrapper>
        <IconWrapper>
          <Icon name="pet" className={getPetIconClass(freeSeats.petSeats)} />
        </IconWrapper>
        <SeatsWrapper>
          <Seats>
            <Number>{freeSeats.petSeats}</Number>
          </Seats>
          <Type>
            <text>Pet in cabin</text>
          </Type>
        </SeatsWrapper>
      </Wrapper>
    );
  }
}
