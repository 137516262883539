import React from 'react';
import { IdTravellersAndSeatsForFlight } from '../../../model/interfaces';
import styled from 'styled-components';
import { color, scale, staticScale, weight } from '../../../styles';
import { isNotPresent } from '../../../../utils/typeUtils';

interface Props {
  details: IdTravellersAndSeatsForFlight | null;
}

const Wrapper = styled.div`
  flex-grow: 1;
  padding: ${scale.spacing.xs};
  background-color: ${color.darkWhite};
`;

const Group = styled.div`
  margin: 0 ${staticScale.spacing.xxs};
`;

const Text = styled.span`
  line-height: ${scale.lineHeight.m};
  font-weight: ${weight.book};
`;

const LightText = styled(Text)`
  font-weight: ${weight.book};
`;

const Number = styled.span`
  font-size: ${scale.font.s};
  line-height: ${scale.lineHeight.s};
  font-weight: ${weight.book};
  color: ${color.darkGray};
  margin-right: ${scale.spacing.xs};
`;

export class Listings extends React.Component<Props> {
  render() {
    const { details } = this.props;

    if (isNotPresent(details) || isNotPresent(details.listings) || details.listings.length === 0) {
      return null;
    }

    return (
      <Wrapper>
        {details.listings.map((x, i: number) => (
          <Group>
            <Number>{i + 1})</Number>
            <Text>{x.type}</Text> - <LightText>{x.startDate}</LightText>
          </Group>
        ))}
      </Wrapper>
    );
  }
}
