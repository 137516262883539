import { Component } from 'react';
import { breakpoint, CenteredGrid, color, createCSSVariableStyleString, weight } from './styles';
import styled, { createGlobalStyle } from 'styled-components';
import FlightSearch from './Components/FlightSearch/FlightSearch';
import FlightList from './Components/FlightList/FlightList';
import { Header } from './Components/Header/Header';

interface Props {}

interface State {
  filters: { [filterGroup: string]: { [filter:string]: boolean} },
}

const Page = styled.div`
  height: 100%;
  color: ${color.black};
  font-size: 0.8rem;
  font-weight: ${weight.book};
`;

const Main = styled(CenteredGrid)``;

const GridHeader = styled.div`
  grid-column: 1 / span 12;
`;

const GridFlightSearch = styled.div`
  grid-column: 1 / span 12;

  @media ${breakpoint.tablet} {
    grid-column: 1 / span 5;
  }
`;
const GridFlightList = styled.div`
  grid-column: 1 / span 12;

  @media ${breakpoint.tablet} {
    grid-column: 6 / span 7;
  }
`;

const RootStyles = createGlobalStyle`
  :root {
    ${createCSSVariableStyleString()}
  }
`;

export default class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const filters: { [filterGroup: string]: { [filter:string]: boolean} } = {
      features: {
        warmDestinations: false,
      },
      locations: {
        america: false,
        asia: false,
        europe: false,
        finland: false
      },
    };
    this.state = {
      filters,
    };
    this.setFilter = this.setFilter.bind(this);
  }

  setFilter(filterGroup: string, filterName: string): void {
    let filters: { [filterGroup: string]: { [filter:string]: boolean} } = JSON.parse(JSON.stringify(this.state.filters));

    for (const key in filters[filterGroup]) {
      filters[filterGroup][key] = false;
    }
    filters[filterGroup][filterName] = !this.state.filters[filterGroup][filterName];

    this.setState({
      filters,
    });
  }

  render() {
    return (
      <Page className="App">
        <RootStyles />
        <Main as="main">
          <GridHeader>
            <Header />
          </GridHeader>
          <GridFlightSearch>
            <FlightSearch />
          </GridFlightSearch>
          <GridFlightList>
            <FlightList filters={this.state.filters} setFilter={this.setFilter} />
          </GridFlightList>
        </Main>
      </Page>
    );
  }
}
