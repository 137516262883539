import styled from 'styled-components';

export const BOX_SHADOW_COLOR = 'rgba(0, 0, 0, 0.13)';

const SIZE_TABLET = 600;
const SIZE_SMALL_DESKTOP = 1024;
const SIZE_BIG_DESKTOP = 1600;

export const ie11Only = (styleString: string, breakpointString: string = 'all'): string => {
  return `
    @media ${breakpointString} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      ${styleString}
    }
    // Prettier ignores this
  `;
};

const BASE_VALUES: { [K in VariableType]: { [L in Breakpoint]: { [M in Size]: string } } } = {
  font: {
    mobile: {
      xxs: '0.6rem',
      xs: '0.7rem',
      s: '0.7rem',
      m: '0.8rem',
      l: '0.9rem',
      xl: '1.2rem',
      xxl: '1.4rem',
    },
    tablet: {
      xxs: '0.7rem',
      xs: '0.8rem',
      s: '0.8rem',
      m: '0.9rem',
      l: '1.2rem',
      xl: '1.6rem',
      xxl: '2.2rem',
    },
    smallDesktop: {
      xxs: '0.7rem',
      xs: '0.8rem',
      s: '0.8rem',
      m: '0.9rem',
      l: '1.2rem',
      xl: '1.6rem',
      xxl: '2.2rem',
    },
    bigDesktop: {
      xxs: '0.8rem',
      xs: '0.9rem',
      s: '0.9rem',
      m: '1.2rem',
      l: '1.4rem',
      xl: '2.0rem',
      xxl: '3.0rem',
    },
    static: {
      xxs: '0.7rem',
      xs: '0.8rem',
      s: '0.8rem',
      m: '1.0rem',
      l: '1.6rem',
      xl: '2.0rem',
      xxl: '3.0rem',
    },
  },
  lineHeight: {
    mobile: {
      xxs: '0.9rem',
      xs: '1.0rem',
      s: '0.8rem',
      m: '0.9rem',
      l: '1.0rem',
      xl: '1.3rem',
      xxl: '1.5rem',
    },
    tablet: {
      xxs: '1.0rem',
      xs: '1.2rem',
      s: '0.9rem',
      m: '1.0rem',
      l: '1.3rem',
      xl: '1.7rem',
      xxl: '2.3rem',
    },
    smallDesktop: {
      xxs: '1.0rem',
      xs: '1.2rem',
      s: '0.9rem',
      m: '1.0rem',
      l: '1.3rem',
      xl: '1.7rem',
      xxl: '2.3rem',
    },
    bigDesktop: {
      xxs: '1.2rem',
      xs: '1.3rem',
      s: '1.0rem',
      m: '1.2rem',
      l: '1.5rem',
      xl: '2.1rem',
      xxl: '3.2rem',
    },
    static: {
      xxs: '1.0rem',
      xs: '1.2rem',
      s: '0.9rem',
      m: '1.1rem',
      l: '1.7rem',
      xl: '2.1rem',
      xxl: '3.2rem',
    },
  },
  spacing: {
    mobile: {
      xxs: '0.2rem',
      xs: '0.4rem',
      s: '0.6rem',
      m: '0.8rem',
      l: '1.2rem',
      xl: '1.6rem',
      xxl: '2.0rem',
    },
    tablet: {
      xxs: '0.4rem',
      xs: '0.6rem',
      s: '0.8rem',
      m: '1.2rem',
      l: '1.6rem',
      xl: '2.0rem',
      xxl: '2.8rem',
    },
    smallDesktop: {
      xxs: '0.6rem',
      xs: '0.8rem',
      s: '1.2rem',
      m: '1.6rem',
      l: '2.0rem',
      xl: '2.8rem',
      xxl: '3.6rem',
    },
    bigDesktop: {
      xxs: '0.8rem',
      xs: '1.0rem',
      s: '1.4rem',
      m: '1.8rem',
      l: '2.4rem',
      xl: '3.2rem',
      xxl: '4.4rem',
    },
    static: {
      xxs: '0.2rem',
      xs: '0.4rem',
      s: '0.6rem',
      m: '0.8rem',
      l: '1.2rem',
      xl: '1.6rem',
      xxl: '2.0rem',
    },
  },
  icon: {
    mobile: {
      xxs: '0.8rem',
      xs: '1.0rem',
      s: '1.2rem',
      m: '1.6rem',
      l: '2.0rem',
      xl: '2.4rem',
      xxl: '2.8rem',
    },
    tablet: {
      xxs: '1.0rem',
      xs: '1.2rem',
      s: '1.4rem',
      m: '2.0rem',
      l: '2.4rem',
      xl: '2.8rem',
      xxl: '3.2rem',
    },
    smallDesktop: {
      xxs: '1.0rem',
      xs: '1.2rem',
      s: '1.4rem',
      m: '2.0rem',
      l: '2.4rem',
      xl: '2.8rem',
      xxl: '3.2rem',
    },
    bigDesktop: {
      xxs: '1.2rem',
      xs: '1.4rem',
      s: '1.6rem',
      m: '2.4rem',
      l: '2.8rem',
      xl: '3.2rem',
      xxl: '3.6rem',
    },
    static: {
      xxs: '1.0rem',
      xs: '1.2rem',
      s: '1.4rem',
      m: '1.6rem',
      l: '2.0rem',
      xl: '2.4rem',
      xxl: '2.8rem',
    },
  },
};

type Size = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
type VariableType = 'spacing' | 'font' | 'lineHeight' | 'icon';
export type Breakpoint = 'mobile' | 'tablet' | 'smallDesktop' | 'bigDesktop' | 'static';

// @ts-ignore
export const isIE11 = (): boolean => !!window.MSInputMethodContext && !!document.documentMode;

const containsOldEdgeUserAgent = (userAgent: string): boolean => {
  const oldEdgeVersions = [12, 13, 14, 15];
  return oldEdgeVersions.some((version) => userAgent.indexOf(`Edge/${version}`) !== -1);
};

export const isOldEdge = (): boolean => {
  return (
    Boolean(window && window.navigator && window.navigator.userAgent && window.navigator.userAgent.indexOf) &&
    containsOldEdgeUserAgent(window.navigator.userAgent)
  );
};

export const isNonSupportedBrowser = (): boolean => isIE11() || isOldEdge();

const toStaticSize = (type: VariableType, size: Size) => BASE_VALUES[type].static[size];
const createCssVariable = (type: VariableType, size: Size): string =>
  isNonSupportedBrowser() ? BASE_VALUES[type].smallDesktop[size] : `var(--${type}-${size})`;

const types: VariableType[] = ['font', 'spacing', 'icon', 'lineHeight'];
const sizes: Size[] = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];

type CssVariableTypeMap = { [key in VariableType]: CssSizeMap };
type CssSizeMap = { [key in Size]: string };

const createCssScaleMap = (toCssString: (type: VariableType, size: Size) => string): CssVariableTypeMap =>
  types.reduce(
    (typeAcc: CssVariableTypeMap, type: VariableType): CssVariableTypeMap => ({
      ...typeAcc,
      [type]: sizes.reduce(
        (sizeAcc: CssSizeMap, size: Size): CssSizeMap => ({
          ...sizeAcc,
          [size]: toCssString(type, size),
        }),
        {} as CssSizeMap
      ),
    }),
    {} as CssVariableTypeMap
  );

export const scale = createCssScaleMap(createCssVariable);
export const staticScale = createCssScaleMap(toStaticSize);

export const styles = {
  color: {
    black: '#1E1E1E',
    blue: '#0B1560',
    warmWhite: '#FCFBFB',
    heather: '#BA4A9E',
    white: '#FFFFFF',
    borderRed: '#e0b0b2',
    borderYellow: '#faf08d',
    borderGreen: '#c2e691',
    borderGray: '#DCDCDC',
    seatRed: '#a58687',
    seatYellow: '#a7a165',
    seatGreen: '#90aa6f',
    seatGray: '#DCDCDC',
    darkGray: '#737373',
    gray: '#AFAFAF',
    lightGray: '#D7D7D7',
    darkWhite: '#F8F8F8',
    warningRed: '#FF0000'
  },
  weight: {
    light: '200',
    book: '300',
    regular: '400',
    medium: '500',
    bold: '700',
  },
  value: {
    borderRadius: '2px',
    rounding: '4px',
    headerHeight: '60px',
    headerHeightDesktop: '100px',
  },
  breakpoint: {
    mobile: `(max-width: ${SIZE_TABLET - 1}px)`,
    tablet: `(min-width: ${SIZE_TABLET}px)`,
    smallDesktop: `(min-width: ${SIZE_SMALL_DESKTOP}px)`,
    bigDesktop: `(min-width: ${SIZE_BIG_DESKTOP}px)`,
  },
  boxShadow: {
    bottomGray: `0 1px 0 0 ${BOX_SHADOW_COLOR}`,
    grayBorders: `inset 0 0 0 1px ${BOX_SHADOW_COLOR}`,
  },
};

export const createCSSVariableStyleString = (): string =>
  Object.keys(BASE_VALUES)
    .map(
      // @ts-ignore
      (valueType: VariableType) => `
      /* CSS variable declarations for ${valueType} */
      ${Object.keys(BASE_VALUES[valueType])
        // @ts-ignore
        .filter((breakpointStr: Breakpoint) => breakpointStr !== 'static')
        // @ts-ignore
        .map((breakpointStr: Exclude<Breakpoint, 'static'>) => {
          const sizeStrings = Object.keys(BASE_VALUES[valueType][breakpointStr]);

          const sizePerBreakpoint = sizeStrings
            // @ts-ignore
            .map((sizeStr: Size) => `--${valueType}-${sizeStr}: ${BASE_VALUES[valueType][breakpointStr][sizeStr]};\n`)
            .join('');

          if (breakpointStr === 'mobile') {
            return sizePerBreakpoint;
          }

          /* Variables for ${valueType}-${breakpointStr} */
          return `
            // @ts-ignore
            @media ${styles.breakpoint[breakpointStr]} {
              ${sizePerBreakpoint}
            }
          `;
        })
        .join('')}
    `
    )
    .join('');

export type Styles = typeof styles;

export const { boxShadow, color, weight, value, breakpoint } = styles;

export const CenteredGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
  margin: 0 auto;

  @media ${styles.breakpoint.smallDesktop} {
    grid-gap: 24px;
    max-width: 976px;
  }
`;
