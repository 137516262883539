import styled from 'styled-components';

import React from 'react';
import { InlineSvgIcon } from '../InlineSvgIcon';
import { breakpoint, color, scale, weight } from '../../styles';

const ContentWrapper = styled.div`
  display: flex;
  margin: ${scale.spacing.m} 0;
`;
const Emblem = styled(InlineSvgIcon)`
  color: ${color.blue};
  align-self: flex-end;

  svg {
    width: 80px;
    @media ${breakpoint.tablet} {
      width: 120px;
    }
  }
`;

const Text = styled.div`
  flex-grow: 1;

  font-size: ${scale.font.xl};
  line-height: ${scale.lineHeight.xl};
  font-weight: ${weight.regular};
  color: ${color.blue};
  text-transform: uppercase;
`;

export class Header extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <Text>ID Flight Availability</Text>
        <Emblem name="emblem" />
      </ContentWrapper>
    );
  }
}
