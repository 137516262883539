import React from 'react';
import Select, { Theme } from 'react-select';
import { City } from '../../model/interfaces';
import styled from 'styled-components';
import { color, staticScale } from '../../styles';

interface Props {
  handleSelectCity: (city: City | null) => void;
  isDeparture: boolean;
  options: { value: string; label: string }[];
  selectedCity: City | null;
}

const Wrapper = styled.div`
  margin-bottom: 8px;
`;

export class CityPicker extends React.Component<Props> {
  onSelect = (selection: any) => {
    if (selection.value === 'XXX') {
      this.props.handleSelectCity(null);
      return;
    }
    this.props.handleSelectCity({ code: selection.value, name: selection.label });
  };

  render() {
    const { selectedCity, options } = this.props;
    return (
      <Wrapper>
        <Select
          options={options}
          theme={(theme: Theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          styles={{
            singleValue: (provided, state) => {
              const fontSize = staticScale.font.xs;
              const lineHeight = staticScale.lineHeight.xs;
              return { ...provided, fontSize, lineHeight };
            },
            indicatorSeparator: (provided) => {
              return { display: 'none' };
            },
            dropdownIndicator: (provided) => {
              return { ...provided, padding: staticScale.spacing.xxs };
            },
            control: (provided) => {
              return { ...provided, backgroundColor: color.darkWhite };
            },
          }}
          onChange={this.onSelect}
          value={selectedCity ? { value: selectedCity.code, label: selectedCity.name } : null}
          placeholder="All destinations"
          isMulti={false}
          isSearchable={true}
        />
      </Wrapper>
    );
  }
}
