import './index.css';
import App from './App/App';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { env } from './env';
import { AuthProvider } from 'App/Components/Auth/Auth';

const isProduction = env.isProduction;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: Function;
  }
}

const reduxDevTools = isProduction() ? undefined : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = reduxDevTools || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
  <AuthProvider>
    <Provider store={store}>
      <App/>
    </Provider>
  </AuthProvider>
);
