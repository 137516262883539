import { Configuration, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { env } from '../../../env';

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: env.getCurrentEnv().azure.azureAdClientId,
    authority: `https://login.microsoftonline.com/${env.getCurrentEnv().azure.azureAdTenant}`,
    redirectUri: env.getCurrentEnv().azure.azureAdRedirectUrl,
    postLogoutRedirectUri: '/',
  },
};

export const msalInstance = new PublicClientApplication({
  cache: {
    cacheLocation: 'sessionStorage'
  },
  auth: {
    clientId: env.getCurrentEnv().azure.azureAdClientId,
    redirectUri: env.getCurrentEnv().azure.azureAdRedirectUrl,
    authority: 'https://login.microsoftonline.com/f4b2d972-f1c8-4f3c-a73b-4f9b2ca3d778',
  }
});

export async function getAccessToken(): Promise<string> {
  const accounts = msalInstance.getAllAccounts();
  msalInstance.setActiveAccount(accounts[0]);

  return (
    await msalInstance.acquireTokenSilent({
      scopes: ['openid', 'profile', 'offline_access', `api://${env.getCurrentEnv().azure.azureAdClientId}/Read`],
    })
  ).accessToken;
}

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        loadingComponent={() => <div>Loading</div>}
        errorComponent={(error: any) => {
          const errorString = JSON.stringify(error);
          if (errorString.includes('is requesting a token for itself')) {
            localStorage.clear();
            sessionStorage.clear();
          }

          return <div>{JSON.stringify(error)}</div>
        }}
        interactionType={InteractionType.Redirect}
      >
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
