import { Action } from '../../utils/createReducer';
import { City, Flight, FlightAvailability, IdTravellersAndSeats, Weather } from '../model/interfaces';
import { AvailabilityThunkAction, AvailabilityThunkDispatch } from '../../rootReducer';
import axios from 'axios';
import moment from 'moment';
import { isPresent } from '../../utils/typeUtils';
import Cookies from 'js-cookie';
import { env } from '../../env';
import { getAccessToken } from 'App/Components/Auth/Auth';

const getCurrentEnv = env.getCurrentEnv;

const querystring = require('querystring');

export const SELECT_REGION_FILTER_ACTION = 'SELECT/REGION_FILTER';
export const SELECT_DEPARTURE_CITY_ACTION = 'SELECT/DEPARTURE_CITY';
export const SELECT_ARRIVAL_CITY_ACTION = 'SELECT/ARRIVAL_CITY';
export const SELECT_DEPARTURE_DATE_ACTION = 'SELECT/DEPARTURE_DATE';

export const SWITCH_CITIES_ACTION = 'SWITCH/CITIES';
export const FILTER_FLIGHTS_ACTION = 'FILTER/FLIGHTS';
export const EXPAND_ROW_ACTION = 'EXPAND/ROW';
export const RESET_EXPANDED_ROWS_ACTION = 'RESET/ROWS';

export const FETCH_FLIGHTS_START = 'FETCH/FLIGHTS/START';
export const FETCH_FLIGHTS_SUCCESS = 'FETCH/FLIGHTS/SUCCESS';
export const FETCH_FLIGHTS_FAIL = 'FETCH/FLIGHTS/FAIL';
export const FETCH_AVAILABILITY_START = 'FETCH/AVAILABILITY/START';
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH/AVAILABILITY/SUCCESS';
export const FETCH_AVAILABILITY_FAIL = 'FETCH/AVAILABILITY/FAIL';
export const FETCH_DATES_START = 'FETCH/DATES/START';
export const FETCH_DATES_SUCCESS = 'FETCH/DATES/SUCCESS';
export const FETCH_DATES_FAIL = 'FETCH/DATES/FAIL';

export const FETCH_WEATHER_START = 'FETCH/WEATHER/START';
export const FETCH_WEATHER_SUCCESS = 'FETCH/WEATHER/SUCCESS';
export const FETCH_WEATHER_FAIL = 'FETCH/WEATHER/FAIL';

export type AvailabilityActionFailTypes =
  | typeof FETCH_FLIGHTS_FAIL
  | typeof FETCH_AVAILABILITY_FAIL
  | typeof FETCH_DATES_FAIL
  | typeof FETCH_WEATHER_FAIL;

export type AvailabilityActionTypes =
  | typeof SELECT_DEPARTURE_CITY_ACTION
  | typeof SELECT_ARRIVAL_CITY_ACTION
  | typeof SWITCH_CITIES_ACTION
  | typeof SELECT_DEPARTURE_DATE_ACTION
  | typeof FETCH_FLIGHTS_START
  | typeof FETCH_FLIGHTS_SUCCESS
  | typeof FETCH_AVAILABILITY_START
  | typeof FETCH_AVAILABILITY_SUCCESS
  | typeof FETCH_DATES_START
  | typeof FETCH_DATES_SUCCESS
  | typeof FILTER_FLIGHTS_ACTION
  | typeof SELECT_REGION_FILTER_ACTION
  | typeof EXPAND_ROW_ACTION
  | typeof RESET_EXPANDED_ROWS_ACTION
  | typeof FETCH_WEATHER_START
  | typeof FETCH_WEATHER_SUCCESS
  | AvailabilityActionFailTypes;

export class AvailabilityActions {
  static initialiseFlights(): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch): Promise<void> => {
      await dispatch(this.createFetchFlightsAction());
    };
  }

  static selectDepartureCity(city: City | null): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch): Promise<void> => {
      dispatch({
        type: SELECT_DEPARTURE_CITY_ACTION,
        payload: city,
      });
      dispatch(this.createFetchFlightsAction());
    };
  }

  static selectArrivalCity(city: City | null): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch): Promise<void> => {
      dispatch({
        type: SELECT_ARRIVAL_CITY_ACTION,
        payload: city,
      });
      dispatch(this.createFetchFlightsAction());
    };
  }

  static switchCities(): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      dispatch({
        type: SWITCH_CITIES_ACTION,
      });
      dispatch(this.createFetchFlightsAction());
    };
  }

  static selectDepartureDate(date: Date | null): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch): Promise<void> => {
      await dispatch({
        type: SELECT_DEPARTURE_DATE_ACTION,
        payload: date,
      });
      await dispatch(this.createFetchFlightsAction());
    };
  }

  static createGenericErrorHandlerAction(type: AvailabilityActionFailTypes, errorResponse: string) {
    return {
      type: type,
      payload: errorResponse,
    };
  }

  static createFetchFlightsSuccessAction(flights: Flight[]): FetchFlightsSuccessAction {
    return {
      type: FETCH_FLIGHTS_SUCCESS,
      payload: flights,
    };
  }

  static createFetchFlightsAction(): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      dispatch({
        type: FETCH_FLIGHTS_START,
      });

      await dispatch(AvailabilityActions.createFetchAvailabilityAction());

      await dispatch(AvailabilityActions.createFetchDatesAction());

      const departureDate = getState().availability.selectedDepartureDate;
      const departureAirport = getState().availability.selectedDepartureCity;
      const arrivalAirport = getState().availability.selectedArrivalCity;

      Cookies.set('departureCity', JSON.stringify(departureAirport), { expires: 3 });
      Cookies.set('arrivalCity', JSON.stringify(arrivalAirport), { expires: 3 });
      if (isPresent(departureDate)) {
        Cookies.set('departureDate', departureDate.toISOString(), { expires: 3 });
      }

      const params = {
        departureDate: departureDate ? moment(departureDate).format('YYYY-MM-DD').toString() : null,
        departureAirport: departureAirport ? departureAirport.code : null,
        arrivalAirport: arrivalAirport ? arrivalAirport.code : null,
      };

      const headers = {
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_flightsApiKey,
      };

      try {
        const response = await axios.get(`${getCurrentEnv().flights.url}/flights?${querystring.stringify(params)}`, {
          headers: headers,
        });

        await dispatch(this.createFetchFlightsSuccessAction(response.data));

        await dispatch(this.createFilterFlightsAction());
        await dispatch(this.resetRows());
      } catch (errorResponse: any) {
        dispatch(this.createGenericErrorHandlerAction(FETCH_FLIGHTS_FAIL, errorResponse.toString()));
      }
    };
  }

  static createFetchAvailabilitySuccessAction(availability: {
    [s: string]: { [s: string]: IdTravellersAndSeats };
  }): FetchAvailabilitySuccessAction {
    return {
      type: FETCH_AVAILABILITY_SUCCESS,
      payload: availability,
    };
  }

  static createFetchAvailabilityAction(): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      dispatch({
        type: FETCH_AVAILABILITY_START,
      });

      const departureDate = getState().availability.selectedDepartureDate;

      const body = {
        queries: [
          {
            date: departureDate ? moment(departureDate).format('YYYY-MM-DD').toString() : null,
            format: 'minimal',
          },
        ],
      };

      const headers = {
        Accept: 'application/json',
        Authorization: await getAccessToken(),
        'x-api-key': process.env.REACT_APP_availabilityApiKey,
      };

      try {
        const response = await axios.post(`${getCurrentEnv().availability.url}/availability/snapshot`, body, {
          headers: headers,
        });

        const result = response.data[0];
        const availableFlights: FlightAvailability[] = result ? result.results : [];

        let parsedData: { [s: string]: { [s: string]: IdTravellersAndSeats } } = {};
        availableFlights.forEach((flight: FlightAvailability) => {
          parsedData[flight.flightNumber] = {};

          const availableFlight: IdTravellersAndSeats = {
            capacity: flight.capacity,
            seatsAvailable: flight.seatsAvailable,
            waitlisted: flight.waitlisted,
            listings: flight.listings,
            timestamp: response.data[0].ts,
          };
          parsedData[flight.flightNumber][flight.time] = availableFlight;
        });

        dispatch(this.createFetchAvailabilitySuccessAction(parsedData));
      } catch (errorResponse: any) {
        console.warn('NO SNAPSHOT FOUND');
        if (errorResponse?.message === 'Network Error' || errorResponse?.response?.status === 403) {
          console.warn('SERVICE RESPONDED WITH 403: REFRESHING PAGE');
        }
        dispatch(this.createFetchAvailabilitySuccessAction({}));
      }
    };
  }

  static createFetchDatesSuccessAction(dates: any): FetchDatesSuccessAction {
    if (dates === null) {
      return {
        type: FETCH_DATES_SUCCESS,
        payload: [],
      };
    }

    const allDays: string[] = [];
    Object.keys(dates).forEach(function (key) {
      allDays.push(key);
    });

    // TODO do this somehow better
    return {
      type: FETCH_DATES_SUCCESS,
      payload: allDays,
    };
  }

  static createFetchDatesAction(): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      dispatch({
        type: FETCH_DATES_START,
      });

      const departureAirport = getState().availability.selectedDepartureCity;
      const arrivalAirport = getState().availability.selectedArrivalCity;

      if (departureAirport === null || arrivalAirport === null) {
        dispatch(this.createFetchDatesSuccessAction(null)); // TODO??
        return;
      }

      
      const departureAirportIata = departureAirport.code;
      const arrivalAirportIata = arrivalAirport ? arrivalAirport.code : null;

      const headers = {
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_flightsApiKey,
      };

      try {
        const response = await axios.get(
          `${getCurrentEnv().flights.url}/schedules/routes/${departureAirportIata}/${arrivalAirportIata}`,
          {
            headers: headers,
          }
        );

        dispatch(this.createFetchDatesSuccessAction(response.data));
      } catch (errorResponse: any) {
        dispatch(this.createGenericErrorHandlerAction(FETCH_FLIGHTS_FAIL, errorResponse.toString()));
      }
    };
  }

  static createFilterFlightsAction(): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      const arrivalAirport = getState().availability.selectedArrivalCity;

      const flights: Flight[] = getState().availability.flights;
      const availabilityData: { [s: string]: { [s: string]: IdTravellersAndSeats } } = getState().availability
        .availability;

      let allFilteredFlights: Flight[] = [];

      if (arrivalAirport) {
        flights.forEach((flight) => {
          if (flight.arrivalStationIATA === arrivalAirport.code) {
            allFilteredFlights.push(flight);
          }
        });
      } else {
        allFilteredFlights = flights.slice();
      }

      // apply availability data
      if (availabilityData) {
        allFilteredFlights = allFilteredFlights.map((flight) => {
          const localDepartureTime = flight.departureTimeLocal.substr(0, 5);
          if (availabilityData[flight.flightDesignator] && availabilityData[flight.flightDesignator][localDepartureTime]) {
            flight.availability = availabilityData[flight.flightDesignator][localDepartureTime];
          }
          return flight;
        });
      }

      dispatch({
        type: FILTER_FLIGHTS_ACTION,
        payload: allFilteredFlights,
      });
    };
  }

  static createFetchWeatherAction(): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      dispatch({
        type: FETCH_WEATHER_START,
      });
      
      try {
        if (getState().availability.selectedArrivalCity) {
          const response = await axios.get(getCurrentEnv().weather.url, {
            headers: { 'x-api-key': process.env.REACT_APP_weatherApiKey },
          });
          dispatch(this.createFetchWeatherSuccessAction(await response.data));
        }

        if (getState().availability.selectedDepartureCity) {
          const response = await axios.get(getCurrentEnv().weather.url, {
            headers: { 'x-api-key': process.env.REACT_APP_weatherApiKey },
            params: {
              airportCode: getState().availability.selectedDepartureCity?.code
            }
          });
          dispatch(this.createFetchWeatherSuccessAction(await response.data));
        }
        
      } catch (errorResponse: any) {
        dispatch(this.createGenericErrorHandlerAction(FETCH_WEATHER_FAIL, errorResponse.toString()));
      }
    };
  }

  static createFetchWeatherSuccessAction(weather: { [city: string]: Weather[] }): FetchWeatherSuccessAction {
    return {
      type: FETCH_WEATHER_SUCCESS,
      payload: weather,
    };
  }

  static selectFilter(region: string): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      dispatch({
        type: SELECT_REGION_FILTER_ACTION,
        payload: region,
      });
      dispatch(this.resetRows());
    };
  }

  static expandRow(row: number): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      dispatch({
        type: EXPAND_ROW_ACTION,
        payload: row,
      });
    };
  }

  static resetRows(): AvailabilityThunkAction<void> {
    return async (dispatch: AvailabilityThunkDispatch, getState): Promise<void> => {
      dispatch({
        type: RESET_EXPANDED_ROWS_ACTION,
      });
    };
  }
}

export type SelectDepartureCityAction = Action<typeof SELECT_DEPARTURE_CITY_ACTION, City | null>;
export type SelectArrivalCityAction = Action<typeof SELECT_ARRIVAL_CITY_ACTION, City | null>;
export type SelectDepartureDateAction = Action<typeof SELECT_DEPARTURE_DATE_ACTION, Date | null>;
export type SwitchCitiesAction = Action<typeof SWITCH_CITIES_ACTION, void>;
export type FetchFlightsSuccessAction = Action<typeof FETCH_FLIGHTS_SUCCESS, Flight[]>;
export type FetchFlightsFailAction = Action<typeof FETCH_FLIGHTS_FAIL, string>;
export type FetchFlightsStartAction = Action<typeof FETCH_FLIGHTS_START, void>;
export type FetchAvailabilitySuccessAction = Action<
  typeof FETCH_AVAILABILITY_SUCCESS,
  { [s: string]: { [s: string]: IdTravellersAndSeats } }
>;
export type FetchAvailabilityFailAction = Action<typeof FETCH_AVAILABILITY_FAIL, string>;
export type FetchAvailabilityStartAction = Action<typeof FETCH_AVAILABILITY_START, void>;
export type FetchDatesSuccessAction = Action<typeof FETCH_DATES_SUCCESS, string[]>;
export type FetchDatesFailAction = Action<typeof FETCH_DATES_FAIL, string>;
export type FetchDatesStartAction = Action<typeof FETCH_DATES_START, void>;
export type FilterFlightsAction = Action<typeof FILTER_FLIGHTS_ACTION, any>;
export type SelectFilterAction = Action<typeof SELECT_REGION_FILTER_ACTION, string>;
export type ExpandRowAction = Action<typeof EXPAND_ROW_ACTION, number>;
export type ResetExpandedRowsAction = Action<typeof RESET_EXPANDED_ROWS_ACTION, void>;
export type FetchWeatherSuccessAction = Action<typeof FETCH_WEATHER_SUCCESS, { [city: string]: Weather[] }>;
export type FetchWeatherFailAction = Action<typeof FETCH_WEATHER_FAIL, string>;
export type FetchWeatherStartAction = Action<typeof FETCH_WEATHER_START, void>;
