import { combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AvailabilityActionTypes } from './App/actions/availability.actions';
import availability, { State } from './App/reducers/availability.reducer';

export interface RootState {
  availability: State;
}

const rootReducer = combineReducers<RootState>({
  // @ts-ignore
  availability,
});

type actionTypes = AvailabilityActionTypes;

export type AvailabilityActions = { type: actionTypes };
export type AvailabilityThunkAction<R> = ThunkAction<R, RootState, void, AvailabilityActions>;
export type AvailabilityThunkDispatch = ThunkDispatch<RootState, void, AvailabilityActions>;

export default rootReducer;
