import * as React from 'react';
import { Component } from 'react';
import { Weather as WeatherModel } from '../../model/interfaces';
import styled from 'styled-components';
import { color, scale, staticScale, weight } from '../../styles';
import { RootState } from '../../../rootReducer';
import { connect } from 'react-redux';
import { InlineSvgIcon } from '../InlineSvgIcon';
import moment from 'moment';
import { WeatherIcon } from './WeatherIcon';

interface Props {
  city: string;
  weather?: { [city: string]: WeatherModel[] };
  selectedDepartureDate?: string;
}

interface State {}

const mapStateToProps = (state: RootState) => {
  return {
    weather: state.availability.weather,
    selectedDepartureDate: state.availability.selectedDepartureDate ? state.availability.selectedDepartureDate.toString() : undefined,
  };
};

const WeatherInfo = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 0.1rem;
  align-items: center;
`;

const Temperature = styled.div`
  font-size: ${scale.font.s};
  line-height: ${scale.lineHeight.s};
  font-weight: ${weight.book};
  color: ${color.darkGray};
  margin: 0 ${staticScale.spacing.xxs};
`;

const MaxWeather = styled.span`
  margin-right: ${staticScale.spacing.xxs};
  color: ${color.darkGray};
  font-weight: ${weight.regular};
`;

const MinWeather = styled.span`
  color: ${color.gray};
`;

export const iataToFinnairCode = (iataCode: string) => {
  switch (iataCode) {
    case 'JFK':
      return 'NYC';
    case 'PEK':
      return 'BJS';
    case 'TXL':
      return 'BER';
    case 'LHR':
      return 'LON';
    case 'LIN':
      return 'MIL';
    case 'MXP':
      return 'MIL';
    case 'SVO':
      return 'MOW';
    case 'KIX':
      return 'OSA';
    case 'CDG':
      return 'PAR';
    case 'FCO':
      return 'ROM';
    case 'ICN':
      return 'SEL';
    case 'PVG':
      return 'SHA';
    case 'ARN':
      return 'STO';
    case 'BMA':
      return 'STO';
    case 'TCI':
      return 'TFS';
    case 'NRT':
      return 'TYO';
    case 'HND':
      return 'TYO';
    default:
      return iataCode;
  }
};

export class Weather extends Component<Props, State> {
  render() {
    const cityCode: string = iataToFinnairCode(this.props.city);

    if (!this.props.weather || !this.props.weather[cityCode]) {
      return null;
    }
    
    const city5DayWeather: WeatherModel[] = this.props.weather[cityCode];
    const currentDateWeather: WeatherModel = city5DayWeather.filter(day => moment(day.date).isSame(this.props.selectedDepartureDate, 'day'))[0];
  
    if (!currentDateWeather) {
      
      return null
    }

    return (
      <WeatherInfo>
        <WeatherIcon name={currentDateWeather.symbol} />
        <Temperature>
          <MaxWeather>{currentDateWeather.maxTemp}°</MaxWeather>
          <MinWeather>{currentDateWeather.minTemp}°</MinWeather>
        </Temperature>
      </WeatherInfo>
    );
  }
}

export default connect(mapStateToProps, null)(Weather);
