export interface AvailabilityConfig {
  url: string;
}

export interface FlightsConfig {
  url: string;
}

export interface AzureConfig {
  azureAdClientId: string;
  azureAdRedirectUrl: string;
  azureAdTenant: string;
}

export interface WeatherConfig {
  url: string;
}

export interface Config {
  availability: AvailabilityConfig;
  flights: FlightsConfig;
  azure: AzureConfig;
  weather: WeatherConfig;
}

const environments: { [env: string]: Config } = {
  local: {
    availability: {
      url: 'https://id-availability-v3.flight.dev.app.finnair.com',
    },
    flights: {
      url: 'https://id-flights-v3.flight.dev.app.finnair.com',
    },
    weather: {
      url: 'https://id-weather-v3.flight.dev.app.finnair.com/weather',
    },
    azure: {
      azureAdClientId: '73f89735-d941-48c4-a8d9-01e4ad3990b0',
      azureAdRedirectUrl: 'http://localhost:3000',
      azureAdTenant: 'f4b2d972-f1c8-4f3c-a73b-4f9b2ca3d778',
    },
  },
  dev: {
    availability: {
      url: 'https://id-availability-v3.flight.dev.app.finnair.com',
    },
    flights: {
      url: 'https://id-flights-v3.flight.dev.app.finnair.com',
    },
    weather: {
      url: 'https://id-weather-v3.flight.dev.app.finnair.com/weather',
    },
    azure: {
      azureAdClientId: '73f89735-d941-48c4-a8d9-01e4ad3990b0',
      azureAdRedirectUrl: 'https://idavailability-dev.finnair.com',
      azureAdTenant: 'f4b2d972-f1c8-4f3c-a73b-4f9b2ca3d778',
    },
  },
  preprod: {
    availability: {
      url: 'https://id-availability-v3-preprod.flight.prod.app.finnair.com',
    },
    flights: {
      url: 'https://id-flights-v3-preprod.flight.prod.app.finnair.com',
    },
    weather: {
      url: 'https://id-weather-v3-preprod.flight.prod.app.finnair.com/weather',
    },
    azure: {
      azureAdClientId: '73f89735-d941-48c4-a8d9-01e4ad3990b0',
      azureAdRedirectUrl: 'https://idavailability-preprod.finnair.com',
      azureAdTenant: 'f4b2d972-f1c8-4f3c-a73b-4f9b2ca3d778',
    },
  },
  prod: {
    availability: {
      url: 'https://id-availability-v3.flight.prod.app.finnair.com',
    },
    flights: {
      url: 'https://id-flights-v3.flight.prod.app.finnair.com',
    },
    weather: {
      url: 'https://id-weather-v3.flight.prod.app.finnair.com/weather',
    },
    azure: {
      azureAdClientId: 'b36c576e-4b2f-4de9-90a7-35fbff95c233',
      azureAdRedirectUrl: 'https://idavailability.finnair.com',
      azureAdTenant: 'f4b2d972-f1c8-4f3c-a73b-4f9b2ca3d778',
    },
  },
};

console.log(process.env)

const targetEnv = process.env.REACT_APP_TARGET_ENV ? process.env.REACT_APP_TARGET_ENV : 'local';

const getCurrentEnv = () => {
  return environments[targetEnv];
};

const isLocalEnv = () => {
  return targetEnv === 'local';
};

const isProduction = () => {
  return targetEnv === 'prod';
};

export const env = { getCurrentEnv, targetEnv, isLocalEnv, isProduction };
