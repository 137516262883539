import * as React from 'react';
import styled from 'styled-components';

export interface LoaderProps {
  inline?: boolean;
}

const StyledLoader = styled.div`
  margin: 20px 0;
  color: #0b1560;

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    animation-fill-mode: both;
    animation: loading-spinner 1.5s infinite ease-in-out;
  }

  .loader {
    font-size: 0.5em;
    color: #0b1560;
    margin: 0 auto;
    position: relative;
    text-indent: -9999em;
    transform: scale(0.9) translateZ(0) translateY(-1em);
    animation-delay: -0.16s;
  }

  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }

  .loader:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  .loader:after {
    left: 1.5em;
  }

  @keyframes loading-spinner {
    0%,
    80%,
    100% {
      box-shadow: 0 1em 0 -0.5em;
    }
    40% {
      box-shadow: 0 1em 0 0;
    }
  }
`;

export default function Loader ({ inline }: LoaderProps) {
  return (
    <StyledLoader className={inline ? 'inline-loader' : ''}>
      <div className="loader" />
    </StyledLoader>
  );
}
