export const LOCATIONS: { [key: string]: string[] } = {
  america: ['DFW', 'HAV', 'JFK', 'LAX', 'MIA', 'NYC', 'ORD', 'POP', 'PVR', 'SEA', 'SFO', 'YTO'],
  asia: [
    'BJS',
    'BKK',
    'BOM',
    'CAN',
    'CKG',
    'DEL',
    'DOH',
    'DXB',
    'GOI',
    'FUK',
    'HKG',
    'HKT',
    'HND',
    'ICN',
    'KBV',
    'KIX',
    'NGO',
    'NKG',
    'NRT',
    'OSA',
    'PEK',
    'PSA',
    'PUS',
    'PVG',
    'SEL',
    'SGN',
    'SHA',
    'SIN',
    'TLV',
    'TSE',
    'TYO',
    'VDA',
    'XIY',
  ],
  europe: [
    'ACE',
    'AGP',
    'ALC',
    'AMS',
    'ARN',
    'ATH',
    'AYT',
    'BCN',
    'BOO',
    'CDG',
    'BGO',
    'BER',
    'BIQ',
    'BLL',
    'BLQ',
    'BMA',
    'BRU',
    'BUD',
    'CFU',
    'CHQ',
    'CPH',
    'CTA',
    'DBV',
    'DLM',
    'DUB',
    'DUS',
    'EDI',
    'FAO',
    'FCO',
    'FNC',
    'FRA',
    'FUE',
    'GDN',
    'GOJ',
    'GOT',
    'GVA',
    'GZP',
    'HAM',
    'HER',
    'IBZ',
    'INN',
    'JSI',
    'JTR',
    'KEF',
    'KGS',
    'KRK',
    'KUF',
    'KZN',
    'LCA',
    'LED',
    'LIN',
    'LIS',
    'LHR',
    'LJU',
    'LON',
    'LLA',
    'LPA',
    'KAZ',
    'MAD',
    'MAH',
    'MAN',
    'MIL',
    'MJT',
    'MLA',
    'MMX',
    'MOW',
    'MSQ',
    'MUC',
    'MXP',
    'NAP',
    'NCE',
    'NRK',
    'OPO',
    'OSL',
    'PAR',
    'PFO',
    'PMI',
    'PRG',
    'PUY',
    'PVK',
    'RHO',
    'RIX',
    'RMI',
    'ROM',
    'SPU',
    'STO',
    'STR',
    'SVG',
    'SVO',
    'SVX',
    'SZG',
    'TAY',
    'TCI',
    'TFN',
    'TFS',
    'TLL',
    'TOS',
    'TRD',
    'TXL',
    'UME',
    'VAR',
    'VBY',
    'VCE',
    'VIE',
    'VNO',
    'VRN',
    'WAW',
    'WRO',
    'ZAG',
    'ZRH',
    'ZTH',
  ],
  finland: ['ENF', 'HEL', 'IVL', 'JOE', 'JYV', 'KAJ', 'KEM', 'KOK', 'KTT', 'KUO', 'KAO', 'MHQ', 'OUL', 'RVN', 'TMP', 'TKU', 'VAA'],
};
