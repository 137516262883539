import React from 'react';
import { TimeAndCity } from '../../../model/interfaces';
import styled from 'styled-components';
import { color, scale, weight } from '../../../styles';
import Weather from '../../Weather/Weather';

interface Props {
  timeAndCity: TimeAndCity;
  showWeather: boolean;
}

const FlightInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
const Time = styled.div`
  font-size: ${scale.font.xl};
  line-height: ${scale.lineHeight.xl};
  font-weight: ${weight.book};
  color: ${color.darkGray};
`;
const City = styled.div`
  font-size: ${scale.font.s};
  line-height: ${scale.lineHeight.s};
  font-weight: ${weight.regular};
  color: ${color.darkGray};
`;
const PlusOne = styled.span`
  font-size: ${scale.font.m};
`;

export class ColumnCity extends React.Component<Props> {
  render() {
    const timeAndCity = this.props.timeAndCity;
    return (
      <FlightInfo>
        <Time>
          {timeAndCity.time.substr(0, 5)}
          {timeAndCity.isNextDay && <PlusOne>+1</PlusOne>}
        </Time>
        <City>{timeAndCity.city}</City>
        {this.props.showWeather && <Weather city={timeAndCity.airportCode} />}
      </FlightInfo>
    );
  }
}
