/* eslint-disable */
import emblem from '!raw-loader!./icons/emblem.svg';
import info from '!raw-loader!./icons/info.svg';
import landing from '!raw-loader!./icons/landing.svg';
import seat from '!raw-loader!./icons/seat.svg';
import takeoff from '!raw-loader!./icons/takeoff.svg';
import switchIcon from '!raw-loader!./icons/switch.svg';
import chevronDown from '!raw-loader!./icons/chevron-down.svg';
import chevronRight from '!raw-loader!./icons/chevron-right.svg';
import oneway from '!raw-loader!./icons/oneway.svg';
import economyClass from '!raw-loader!./icons/economy-class.svg';
import premiumClass from '!raw-loader!./icons/premium-economy-class.svg';
import businessClass from '!raw-loader!./icons/business-class.svg';
import pet from '!raw-loader!./icons/pet.svg';
import weatherD000 from '!raw-loader!./icons/weather-d000.svg';
import weatherD100 from '!raw-loader!./icons/weather-d100.svg';
import weatherD200 from '!raw-loader!./icons/weather-d200.svg';
import weatherD210 from '!raw-loader!./icons/weather-d210.svg';
import weatherD211 from '!raw-loader!./icons/weather-d211.svg';
import weatherD212 from '!raw-loader!./icons/weather-d212.svg';
import weatherD220 from '!raw-loader!./icons/weather-d220.svg';
import weatherD240 from '!raw-loader!./icons/weather-d240.svg';
import weatherD400 from '!raw-loader!./icons/weather-d400.svg';
import weatherD410 from '!raw-loader!./icons/weather-d410.svg';
import weatherD411 from '!raw-loader!./icons/weather-d411.svg';
import weatherD412 from '!raw-loader!./icons/weather-d412.svg';
import weatherD420 from '!raw-loader!./icons/weather-d420.svg';
import weatherD422 from '!raw-loader!./icons/weather-d422.svg';
import weatherD440 from '!raw-loader!./icons/weather-d440.svg';
import weatherD500 from '!raw-loader!./icons/weather-d500.svg';
import weatherD600 from '!raw-loader!./icons/weather-d600.svg';

export const icons = {
  emblem,
  info,
  landing,
  seat,
  takeoff,
  switchIcon,
  chevronDown,
  chevronRight,
  oneway,
  economyClass,
  premiumClass,
  businessClass,
  pet,
  weatherD000,
  weatherD100,
  weatherD200,
  weatherD210,
  weatherD211,
  weatherD212,
  weatherD220,
  weatherD240,
  weatherD400,
  weatherD410,
  weatherD411,
  weatherD412,
  weatherD420,
  weatherD422,
  weatherD440,
  weatherD500,
  weatherD600,
};
